/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';

const FormEvaluasi = ({ poinEvaluasi }) => (
  <div className="card">
    <div className="card-header bg-primary bg-opacity-10 text-primary fw-bold">
      {poinEvaluasi.pertanyaan}
    </div>
    <div className="card-body">
      <textarea type="text" className="form-control" value={poinEvaluasi.jawaban} rows={4} />
    </div>
  </div>
);

FormEvaluasi.propTypes = {
  poinEvaluasi: PropTypes.shape({
    pertanyaan: PropTypes.string.isRequired,
    jawaban: PropTypes.string.isRequired
  }).isRequired
};

export default FormEvaluasi;
