/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

const JobDescFilter = () => (
  <div className="card">
    <div className="card-header bg-primary bg-opacity-10 text-primary fw-bold">
      <div className="mb-0">Filter Jabatan</div>
    </div>

    <div className="card-body">
      <div className="row">
        <div className="col-lg-4 mb-3">
          <label className="form-label">Unit Kerja</label>
          <input type="text" className="form-control" />
        </div>
        <div className="col-lg-4 mb-3">
          <label className="form-label">JobDesc</label>
          <input type="text" className="form-control" />
        </div>
        <div className="col-lg-4 mb-3">
          <label className="form-label">Jabatan</label>
          <input type="text" className="form-control" />
        </div>
      </div>
      <div className="d-flex flex-row-reverse">
        <button type="button" className="btn btn-primary my-1 me-2">Filter</button>
        <button type="button" className="btn btn-light my-1 me-2">Reset</button>
      </div>
    </div>
  </div>
);

export default JobDescFilter;
