import { createSlice } from '@reduxjs/toolkit';
import userAccount from '../dummy_data/userAccount';

const userSlice = createSlice({
  name: 'user',
  initialState: userAccount,
  reducers: {
    changeNightMode: (state) => {
      state.isNightMode = !state.isNightMode;
    }
  }
});

const { actions, reducer } = userSlice;
export const { changeNightMode } = actions;
export default reducer;
