import PropTypes from 'prop-types';

const SimpleList = ({ data, renderItem, isIndexExist }) => (
  isIndexExist
    ? (data.map((item) => (renderItem(item))))
    : (data.map((item, index) => (renderItem(item, index))))
);

SimpleList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape()
  ).isRequired,
  renderItem: PropTypes.func.isRequired,
  isIndexExist: PropTypes.bool
};

SimpleList.defaultProps = {
  isIndexExist: true
};

export default SimpleList;
