import PropTypes from 'prop-types';
import SimpleList from '../../../Components/SimpleList/SimpleList';
import TablePagination from '../../../Components/TablePagination/TablePagination';
import PenjadwalanTableRow from './PenjadwalanTableRow';

const PenjadwalanTable = ({ uhcList }) => (
  <>
    <div className="card">
      <div className="card-body p-0">
        <div className="table-responsive border rounded">
          <table className="table">
            <thead className="bg-primary bg-opacity-10 text-primary">
              <tr>
                <th>Usulan Karyawan</th>
                <th>Posisi Sekarang</th>
                <th>Posisi Usulan</th>
                <th>Progress</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              <SimpleList
                data={uhcList}
                renderItem={(item) => (
                  <PenjadwalanTableRow
                    key={item.id}
                    uhc={item}
                  />
                )}
              />
            </tbody>
          </table>
        </div>

      </div>
    </div>
    <TablePagination />
  </>
);

PenjadwalanTable.propTypes = {
  uhcList: PropTypes.arrayOf(
    PropTypes.shape()
  ).isRequired
};

export default PenjadwalanTable;
