const karyawanPengusul = [
  {
    id: 1,
    npk: '1',
    nama: 'VP Keamanan'
  },
  {
    id: 2,
    npk: '2',
    nama: 'bypass'
  },
  {
    id: 3,
    npk: '3',
    nama: 'bypass'
  }
];

export default karyawanPengusul;
