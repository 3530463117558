import EvaluasiBerjenjangTable from './EvaluasiBerjenjangTable';
import UploadEvaluasiModal from './UploadEvaluasiModal';

const EvaluasiBerjenjangPage = () => (
  <div className="page-content">
    <div className="content-wrapper">
      <div className="content-inner">
        <div className="page-header">
          <div className="page-header-content container d-lg-flex">
            <div className="d-flex">
              <h4 className="page-title mb-0">
                Evaluasi Berjenjang
              </h4>
            </div>
          </div>
        </div>
        <div className="content container pt-0">
          <EvaluasiBerjenjangTable />
        </div>
      </div>
    </div>
    <UploadEvaluasiModal />
  </div>
);

export default EvaluasiBerjenjangPage;
