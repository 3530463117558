import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

const UsulanPromosiBertahapTableRow = ({ usulan }) => {
  const MySwal = withReactContent(Swal);

  const buatUsulan = () => {
    MySwal.fire({
      icon: 'warning',
      title: 'Apakah anda yakin?',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonText: 'Ya',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-flat-primary',
        cancelButton: 'btn btn-light '
      }
    });
  };

  return (
    <tr>
      <td className="cell-align-top">
        <p className="fs-sm mb-0">NPK:</p>
        <p>{usulan.npk}</p>
        <p className="fs-sm mb-0">NPK:</p>
        <p>{usulan.nama}</p>
        <p className="fs-sm mb-0">PBP:</p>
        <p>{usulan.pbp}</p>
      </td>
      <td className="cell-align-top">
        <p className="fs-sm mb-0">Unit Kerja</p>
        <p>{usulan.posisiSekarang.unitKerja}</p>
        <p className="fs-sm mb-0">Jabatan</p>
        <p className="text-truncate w-120px" data-tip data-for={`tooltip-jabatan-sekarang-${usulan.id}`}>
          {usulan.posisiSekarang.jabatan}
        </p>
        <ReactTooltip id={`tooltip-jabatan-sekarang-${usulan.id}`} className="wmax-160px text-center">
          {usulan.posisiSekarang.jabatan}
        </ReactTooltip>
        <p className="fs-sm mb-0">JG | PG</p>
        <p>
          {usulan.posisiSekarang.jobGrade}
          {' '}
          |
          {' '}
          {usulan.posisiSekarang.personalGrade}
        </p>
        <p className="fs-sm mb-0">Atasan</p>
        <p>{usulan.posisiSekarang.atasan}</p>
        <p className="fs-sm mb-0">Lokasi</p>
        <p>{usulan.posisiSekarang.lokasi}</p>
      </td>
      <td className="cell-align-top">
        <p className="fs-sm mb-0">TMT J</p>
        <p>{usulan.tmtj}</p>
        <p className="fs-sm mb-0">MDJ</p>
        <p>{usulan.mdj}</p>
        <p className="fs-sm mb-0">TMT G</p>
        <p>{usulan.tmtg}</p>
        <p className="fs-sm mb-0">MDG</p>
        <p>{usulan.mdg}</p>
      </td>
      <td className="cell-action cell-align-top">
        <button
          type="button"
          onClick={buatUsulan}
          className="btn btn-flat-primary btn-sm"
        >
          Buat Usulan
        </button>
      </td>
    </tr>
  );
};

UsulanPromosiBertahapTableRow.propTypes = {
  usulan: PropTypes.shape({
    id: PropTypes.number.isRequired,
    npk: PropTypes.string.isRequired,
    nama: PropTypes.string.isRequired,
    mdj: PropTypes.string.isRequired,
    mdg: PropTypes.string.isRequired,
    pbp: PropTypes.string.isRequired,
    tmtj: PropTypes.string.isRequired,
    tmtg: PropTypes.string.isRequired,
    positionId: PropTypes.string.isRequired,
    posisiSekarang: PropTypes.shape({
      unitKerja: PropTypes.string.isRequired,
      jabatan: PropTypes.string.isRequired,
      jobGrade: PropTypes.string.isRequired,
      personalGrade: PropTypes.string.isRequired,
      atasan: PropTypes.string.isRequired,
      lokasi: PropTypes.string.isRequired
    })
  }).isRequired
};

export default UsulanPromosiBertahapTableRow;
