import PropTypes from 'prop-types';
import SKKFilter from './SKKFilter';
import SKKTable from './SKKTable';

const AdministrasiTab = ({ skk }) => (
  <div className="card-body">
    <SKKFilter />
    <SKKTable skk={skk} skkType="Administrasi" />
  </div>
);

AdministrasiTab.propTypes = {
  skk: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      npk: PropTypes.string.isRequired,
      nama: PropTypes.string.isRequired,
      usulan: PropTypes.string.isRequired,
      tanggalDibuat: PropTypes.string.isRequired,
      tanggalUsulan: PropTypes.string.isRequired,
      noUsulan: PropTypes.string.isRequired,
      usulanDari: PropTypes.string.isRequired,
      mdg: PropTypes.string.isRequired,
      pbp: PropTypes.string.isRequired,
      posisiSekarang: PropTypes.shape({
        unitKerja: PropTypes.string.isRequired,
        jabatan: PropTypes.string.isRequired,
        jobGrade: PropTypes.string.isRequired,
        personalGrade: PropTypes.string.isRequired,
        atasan: PropTypes.string.isRequired,
        lokasi: PropTypes.string.isRequired
      }),
      posisiUsulan: PropTypes.shape({
        unitKerja: PropTypes.string.isRequired,
        jabatan: PropTypes.string.isRequired,
        jobGrade: PropTypes.string.isRequired,
        personalGrade: PropTypes.string.isRequired,
        atasan: PropTypes.string.isRequired,
        lokasi: PropTypes.string.isRequired
      })
    }).isRequired
  ).isRequired
};

export default AdministrasiTab;
