/* eslint-disable jsx-a11y/label-has-associated-control */

import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router';
import usulanRMP from '../../../../dummy_data/usulanRMP';
import karyawanPengusul from '../../../../dummy_data/karyawanPengusul';
import KaryawanDetail from './KaryawanDetail';
import karyawanUsulanBaru from '../../../../dummy_data/karyawanUsulanBaru';

/* eslint-disable jsx-a11y/label-has-for */
const UsulanForm = () => {
  const navigate = useNavigate();
  const [NPK, setNPK] = useState('');
  const [karyawan, setKaryawan] = useState(null);
  const [hasSearchedNPK, setHasSearchedNPK] = useState(false);
  const [NPKPengusul, setNPKPengusul] = useState('');
  const [pengusul, setPengusul] = useState(null);
  const [hasSearchedNPKPengusul, setHasSearchedNPKPengusul] = useState(false);

  const activateBtnCariNPK = useCallback(() => {
    const btnCariNPK = document.getElementById('btn-cari-npk');
    if (btnCariNPK) {
      if (NPK === '') {
        return btnCariNPK.classList.add('disabled');
      }
      return btnCariNPK.classList.remove('disabled');
    }
    return null;
  }, [NPK]);

  const onChangeNPK = (event) => {
    setNPK(event.target.value);
  };

  const searchNPK = () => {
    setHasSearchedNPK(true);
    const findKaryawan = usulanRMP.find((item) => item.npk === NPK);
    setKaryawan(findKaryawan);

    if (!findKaryawan && NPK.length < 6 && NPK.length > 1) {
      setKaryawan(karyawanUsulanBaru);
    }
  };

  const activateBtnCariNPKPengusul = useCallback(() => {
    const btnCariNPKPengusul = document.getElementById('btn-cari-npk-pengusul');
    if (btnCariNPKPengusul) {
      if (NPKPengusul === '') {
        return btnCariNPKPengusul.classList.add('disabled');
      }
      return btnCariNPKPengusul.classList.remove('disabled');
    }
    return null;
  }, [NPKPengusul]);

  const onChangeNPKPengusul = (event) => {
    setNPKPengusul(event.target.value);
  };

  const searchNPKPengusul = () => {
    setHasSearchedNPKPengusul(true);
    const findPengusul = karyawanPengusul.find((item) => item.npk === NPKPengusul);
    setPengusul(findPengusul);
  };

  const activateBtnBuatUsulan = useCallback(() => {
    const btnBuatUsulan = document.getElementById('btn-buat-usulan');
    if (btnBuatUsulan) {
      if (karyawan) {
        return btnBuatUsulan.classList.remove('disabled');
      }
      return btnBuatUsulan.classList.add('disabled');
    }
    return null;
  }, [karyawan]);

  const navigateToUsulan = () => {
    navigate('/rmp/usulan');
  };

  activateBtnCariNPK();
  activateBtnCariNPKPengusul();
  activateBtnBuatUsulan();

  return (
    <>
      <div className="card">
        <div className="card-header bg-primary bg-opacity-10 text-primary fw-bold">
          Usulan Baru
        </div>
        <div className="card-body">
          <div className="row mb-3">
            <label className="col-form-label col-2">NPK</label>
            <div className="col-4">
              <div className="input-group">
                <input type="number" className="form-control" onChange={onChangeNPK} defaultValue={NPK} min="0" />
                <button className="btn btn-primary disabled" id="btn-cari-npk" type="button" onClick={searchNPK}>Cari NPK</button>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-form-label col-2">No Surat</label>
            <div className="col-4">
              <div className="input-group">
                <input type="text" className="form-control" placeholder="Jika tidak ada, isi 'leader'" />
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-form-label col-2">Diusulkan oleh</label>
            <div className="col-4">
              <div className="input-group">
                <input type="number" className="form-control" onChange={onChangeNPKPengusul} defaultValue={NPKPengusul} placeholder="Kepala Departemen/NPK" />
                <button className="btn btn-primary disabled" id="btn-cari-npk-pengusul" type="button" onClick={searchNPKPengusul}>Cari NPK</button>
              </div>
            </div>
            { hasSearchedNPKPengusul && (pengusul ? (
              <div className="col-4">
                <div className="input-group">
                  <input type="text" className="form-control" value={pengusul.nama} disabled />
                </div>
              </div>
            ) : (
              <div className="col-4 align-self-center">
                <span className="text-danger">NPK pengusul tidak ditemukan!</span>
              </div>
            ))}
          </div>
          <div className="row mb-3">
            <label className="col-form-label col-2">Tanggal</label>
            <div className="col-4">
              <div className="input-group">
                <input type="date" className="form-control" />
              </div>
            </div>
          </div>
        </div>
      </div>

      { hasSearchedNPK && (karyawan ? (
        <KaryawanDetail karyawan={karyawan} />
      ) : (
        <div className="alert alert-danger border-0 alert-dismissible fade show">
          NPK karyawan tidak ditemukan!
        </div>
      ))}

      <div className="text-end">
        <button type="submit" className="btn btn-light me-3" onClick={navigateToUsulan}>
          Batal
        </button>
        <button type="submit" className="btn btn-primary disabled" id="btn-buat-usulan">
          Buat Usulan
        </button>
      </div>
    </>
  );
};

export default UsulanForm;
