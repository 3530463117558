import ThemeConfigurator from './ThemeConfigurator';

const SettingPage = () => (
  <div className="page-content">
    <div className="content-wrapper">
      <div className="content-inner">
        <div className="page-header">
          <div className="page-header-content container d-lg-flex">
            <div className="d-flex">
              <h4 className="page-title mb-0">
                Home -
                {' '}
                <span className="fw-normal">Dashboard</span>
              </h4>
            </div>
          </div>
        </div>
        <div className="content container pt-0">
          <div className="row">
            <div className="col-4">
              <ThemeConfigurator />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SettingPage;
