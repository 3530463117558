const JobDescDetailTableRow = () => (
  <tr>
    <td className="cell-align-top">
      <p className="fs-sm mb-0">Sort ID/PID</p>
      <p>111</p>
      <p className="fs-sm mb-0">Jabatan</p>
      <p>VP Administrasi Korporasi</p>
      <p className="fs-sm mb-0">Job Grade</p>
      <p>2A</p>
      <p className="fs-sm mb-0">WF</p>
      <p>Struktural</p>
    </td>
    <td className="cell-align-top">
      <p className="fs-sm mb-0">Status</p>
      <p>Tersedia</p>
      <p className="fs-sm mb-0">Keterangan</p>
      <p>TTD</p>
      <p className="fs-sm mb-0">Format</p>
      <p>Baru</p>
      <p className="fs-sm mb-0">Tahun</p>
      <p>2021</p>
    </td>

    <td className="cell-align-top">
      <p className="fs-sm mb-0">No Surat</p>
      <p>31679/D/OT/D35400/IT/2021</p>
      <a href="https://drive.google.com/file/d/16wASgcvNNZeg5OEM1E1U8EH0QB_kYqNo/view?usp=sharing" target="_blank" rel="noreferrer">Link surat</a>
    </td>
    <td className="cell-action cell-align-top text-center">
      <button type="button" className="btn btn-flat-primary btn-sm" data-bs-toggle="modal" data-bs-target="#modal-update-jabatan">
        Update
      </button>
    </td>
  </tr>
);

export default JobDescDetailTableRow;
