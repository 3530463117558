import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { formatDateToInternationalFormat } from '../../../utils/utils';

const HasilTableRow = ({ uhc }) => {
  const MySwal = withReactContent(Swal);

  const selesai = () => {
    MySwal.fire({
      icon: 'warning',
      title: 'Apakah anda yakin?',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonText: 'Ya',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-flat-primary',
        cancelButton: 'btn btn-light '
      }
    });
  };

  const statusUsulanBadgeColor = () => {
    if (uhc.statusUsulan === 'Lanjut') {
      return 'bg-success';
    }
    if (uhc.statusUsulan === 'Gagal') {
      return 'bg-light text-body';
    }
    return '';
  };

  return (
    <tr>
      <td className="cell-align-top">
        <p className="fs-sm mb-0">No Usulan</p>
        <p>{uhc.noUsulan}</p>
        <p className="fs-sm mb-0">NPK</p>
        <p>{uhc.npk}</p>
        <p className="fs-sm mb-0">Nama</p>
        <p>{uhc.nama}</p>
      </td>
      <td className="cell-align-top">
        <p className="fs-sm mb-0">Unit Kerja</p>
        <p>{uhc.posisiSekarang.unitKerja}</p>
        <p className="fs-sm mb-0">Jabatan</p>
        <p className="text-truncate w-120px" data-tip data-for={`tooltip-jabatan-sekarang-${uhc.id}`}>
          {uhc.posisiSekarang.jabatan}
        </p>
        <ReactTooltip id={`tooltip-jabatan-sekarang-${uhc.id}`} className="wmax-120px text-center">
          {uhc.posisiSekarang.jabatan}
        </ReactTooltip>
        <p className="fs-sm mb-0">JG | PG</p>
        <p>
          {uhc.posisiSekarang.jobGrade}
          {' '}
          |
          {' '}
          {uhc.posisiSekarang.personalGrade}
        </p>
        <p className="fs-sm mb-0">Lokasi</p>
        <p>{uhc.posisiSekarang.lokasi}</p>
      </td>
      <td className="cell-align-top">
        <p className="fs-sm mb-0">Unit Kerja</p>
        <p>{uhc.posisiUsulan.unitKerja}</p>
        <p className="fs-sm mb-0">Jabatan</p>
        <p className="text-truncate w-120px" data-tip data-for={`tooltip-jabatan-usulan-${uhc.id}`}>{uhc.posisiUsulan.jabatan}</p>
        <ReactTooltip id={`tooltip-jabatan-usulan-${uhc.id}`} className="wmax-120px text-center">
          {uhc.posisiUsulan.jabatan}
        </ReactTooltip>
        <p className="fs-sm mb-0">JG | PG</p>
        <p>
          {uhc.posisiUsulan.jobGrade}
          {' '}
          |
          {' '}
          {uhc.posisiUsulan.personalGrade}
        </p>
        <p className="fs-sm mb-0">Lokasi</p>
        <p>{uhc.posisiUsulan.lokasi}</p>
      </td>
      <td className="cell-align-top">
        <button
          type="button"
          className="btn btn-flat-secondary btn-sm mb-2"
          data-bs-toggle="modal"
          data-bs-target="#modal-catatan-rmp"
        >
          Catatan RMP
        </button>
        <p className="fs-sm mb-0">PIC Penjadwalan</p>
        <p>{uhc.picPenjadwalan}</p>
        <p className="fs-sm mb-0">Tanggal Permintaan Jadwal</p>
        <p>{formatDateToInternationalFormat(uhc.tanggalPermintaanJadwal)}</p>
        <p className="fs-sm mb-0">Tanggal Pengisian Jadwal</p>
        <p>{formatDateToInternationalFormat(uhc.tanggalPengisianJadwal)}</p>
        <p className="fs-sm mb-0">PIC Pelaksanaan</p>
        <p>{uhc.picPelaksanaan}</p>
        <p className="fs-sm mb-0">Hari / Tanggal / Waktu</p>
        <p>26 Aug 2022</p>
        <p className="fs-sm mb-0">Tempat</p>
        <p>Aula</p>
        <p className="fs-sm mb-0">Asesor</p>
        <p>Ratna</p>
        <p className="fs-sm mb-0">Witness</p>
        <p>Endah</p>
        { uhc.suratPenilaianUHC && (
          <>
            <p className="fs-sm mb-0">Surat Penilaian UHC</p>
            <p>
              {uhc.suratPenilaianUHC}
              {' '}
              /
              {' '}
              {uhc.tanggalPenilaianUHC}
            </p>
            <p className="fs-sm mb-0">Hasil Penilaian UHC</p>
            <p>
              {uhc.rekomendasiUHC}
              {' '}
              {uhc.hasilPenilaianUHC}
            </p>
            <p className="fs-sm mb-0">Status Usulan</p>
            <span className={`badge ${statusUsulanBadgeColor()}`}>{uhc.statusUsulan}</span>
          </>
        )}
      </td>
      <td className="cell-action cell-align-top text-center">
        <button type="button" className="btn btn-flat-secondary btn-sm" data-bs-toggle="modal" data-bs-target="#modal-update-hasil">
          Update Hasil
        </button>
        {uhc.suratPenilaianUHC && (
          <>
            <br />
            <button type="button" className="btn btn-flat-danger btn-sm mt-2" onClick={selesai}>
              Selesai
            </button>
          </>
        )}
      </td>
    </tr>
  );
};

HasilTableRow.defaultProps = {
  uhc: {
    suratPenilaianUHC: '',
    tanggalPenilaianUHC: '',
    rekomendasiUHC: '',
    hasilPenilaianUHC: '',
    statusUsulan: ''
  }
};

HasilTableRow.propTypes = {
  uhc: PropTypes.shape({
    id: PropTypes.number.isRequired,
    npk: PropTypes.string.isRequired,
    nama: PropTypes.string.isRequired,
    tanggalPermintaanUHC: PropTypes.string.isRequired,
    tanggalUsulan: PropTypes.string.isRequired,
    noUsulan: PropTypes.string.isRequired,
    usulanDari: PropTypes.string.isRequired,
    statusJobdesc: PropTypes.string.isRequired,
    catatanRMP: PropTypes.string.isRequired,
    tanggalPermintaanJadwal: PropTypes.string.isRequired,
    tanggalPengisianJadwal: PropTypes.string.isRequired,
    picPenjadwalan: PropTypes.string.isRequired,
    picPelaksanaan: PropTypes.string.isRequired,
    suratPenilaianUHC: PropTypes.string,
    tanggalPenilaianUHC: PropTypes.string,
    statusUsulan: PropTypes.string.isRequired,
    rekomendasiUHC: PropTypes.string.isRequired,
    hasilPenilaianUHC: PropTypes.string.isRequired,
    posisiSekarang: PropTypes.shape({
      unitKerja: PropTypes.string.isRequired,
      jabatan: PropTypes.string.isRequired,
      jobGrade: PropTypes.string.isRequired,
      personalGrade: PropTypes.string.isRequired,
      atasan: PropTypes.string.isRequired,
      lokasi: PropTypes.string.isRequired
    }),
    posisiUsulan: PropTypes.shape({
      unitKerja: PropTypes.string.isRequired,
      jabatan: PropTypes.string.isRequired,
      jobGrade: PropTypes.string.isRequired,
      personalGrade: PropTypes.string.isRequired,
      atasan: PropTypes.string.isRequired,
      lokasi: PropTypes.string.isRequired
    })
  })
};

export default HasilTableRow;
