import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const JobDescTableRow = ({ uhc }) => {
  const MySwal = withReactContent(Swal);

  const statusJobDescBadgeColor = () => {
    if (uhc.statusJobdesc === 'Ada') {
      return 'bg-success';
    }
    if (uhc.statusJobdesc === 'Tidak Ada') {
      return 'bg-light text-body';
    }
    return '';
  };

  const kePenjadwalan = () => {
    MySwal.fire({
      icon: 'warning',
      title: 'Apakah anda yakin?',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonText: 'Ya',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-flat-primary',
        cancelButton: 'btn btn-light '
      }
    });
  };

  return (
    <tr>
      <td className="cell-align-top">
        <p className="fs-sm mb-0">No Usulan</p>
        <p>{uhc.noUsulan}</p>
        <p className="fs-sm mb-0">NPK</p>
        <p>{uhc.npk}</p>
        <p className="fs-sm mb-0">Nama</p>
        <p>{uhc.nama}</p>
      </td>
      <td className="cell-align-top">
        <p className="fs-sm mb-0">Unit Kerja</p>
        <p>{uhc.posisiSekarang.unitKerja}</p>
        <p className="fs-sm mb-0">Jabatan</p>
        <p className="text-truncate w-120px" data-tip data-for={`tooltip-jabatan-sekarang-${uhc.id}`}>
          {uhc.posisiSekarang.jabatan}
        </p>
        <ReactTooltip id={`tooltip-jabatan-sekarang-${uhc.id}`} className="wmax-120px text-center">
          {uhc.posisiSekarang.jabatan}
        </ReactTooltip>
        <p className="fs-sm mb-0">JG | PG</p>
        <p>
          {uhc.posisiSekarang.jobGrade}
          {' '}
          |
          {' '}
          {uhc.posisiSekarang.personalGrade}
        </p>
        <p className="fs-sm mb-0">Lokasi</p>
        <p>{uhc.posisiSekarang.lokasi}</p>
      </td>
      <td className="cell-align-top">
        <p className="fs-sm mb-0">Unit Kerja</p>
        <p>{uhc.posisiUsulan.unitKerja}</p>
        <p className="fs-sm mb-0">Jabatan</p>
        <p className="text-truncate w-120px" data-tip data-for={`tooltip-jabatan-usulan-${uhc.id}`}>{uhc.posisiUsulan.jabatan}</p>
        <ReactTooltip id={`tooltip-jabatan-usulan-${uhc.id}`} className="wmax-120px text-center">
          {uhc.posisiUsulan.jabatan}
        </ReactTooltip>
        <p className="fs-sm mb-0">JG | PG</p>
        <p>
          {uhc.posisiUsulan.jobGrade}
          {' '}
          |
          {' '}
          {uhc.posisiUsulan.personalGrade}
        </p>
        <p className="fs-sm mb-0">Lokasi</p>
        <p>{uhc.posisiUsulan.lokasi}</p>
      </td>
      <td className="cell-align-top text-center">
        <span className={`badge ${statusJobDescBadgeColor()}`}>{uhc.statusJobdesc}</span>
      </td>
      <td className="cell-action cell-align-top text-center">
        <button type="button" className="btn btn-flat-danger btn-sm" onClick={kePenjadwalan}>
          Ke Penjadwalan
        </button>
      </td>
    </tr>
  );
};

JobDescTableRow.propTypes = {
  uhc: PropTypes.shape({
    id: PropTypes.number.isRequired,
    npk: PropTypes.string.isRequired,
    nama: PropTypes.string.isRequired,
    tanggalPermintaanUHC: PropTypes.string.isRequired,
    tanggalUsulan: PropTypes.string.isRequired,
    noUsulan: PropTypes.string.isRequired,
    usulanDari: PropTypes.string.isRequired,
    statusJobdesc: PropTypes.string.isRequired,
    catatanRMP: PropTypes.string.isRequired,
    posisiSekarang: PropTypes.shape({
      unitKerja: PropTypes.string.isRequired,
      jabatan: PropTypes.string.isRequired,
      jobGrade: PropTypes.string.isRequired,
      personalGrade: PropTypes.string.isRequired,
      atasan: PropTypes.string.isRequired,
      lokasi: PropTypes.string.isRequired
    }),
    posisiUsulan: PropTypes.shape({
      unitKerja: PropTypes.string.isRequired,
      jabatan: PropTypes.string.isRequired,
      jobGrade: PropTypes.string.isRequired,
      personalGrade: PropTypes.string.isRequired,
      atasan: PropTypes.string.isRequired,
      lokasi: PropTypes.string.isRequired
    })
  }).isRequired
};

export default JobDescTableRow;
