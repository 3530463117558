import { useState } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import SimpleList from '../../../../Components/SimpleList/SimpleList';
import TablePagination from '../../../../Components/TablePagination/TablePagination';
import SKKTableRow from './SKKTableRow';

const SKKTable = ({ skk, skkType }) => {
  const [checked, setChecked] = useState(0);
  const MySwal = withReactContent(Swal);

  const kePrint = () => {
    MySwal.fire({
      icon: 'warning',
      title: 'Apakah anda yakin?',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonText: 'Ya',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-flat-primary',
        cancelButton: 'btn btn-light '
      }
    });
  };

  const kePenandatanganan = () => {
    MySwal.fire({
      icon: 'warning',
      title: 'Apakah anda yakin?',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonText: 'Ya',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-flat-primary',
        cancelButton: 'btn btn-light '
      }
    });
  };

  const selesai = () => {
    MySwal.fire({
      icon: 'warning',
      title: 'Apakah anda yakin?',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonText: 'Ya',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-flat-primary',
        cancelButton: 'btn btn-light '
      }
    });
  };

  const keAdministrasi = () => {
    MySwal.fire({
      icon: 'warning',
      title: 'Apakah anda yakin?',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonText: 'Ya',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-flat-primary',
        cancelButton: 'btn btn-light '
      }
    });
  };

  const batalkan = () => {
    MySwal.fire({
      icon: 'warning',
      title: 'Apakah anda yakin?',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonText: 'Ya',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-flat-primary',
        cancelButton: 'btn btn-light '
      }
    });
  };

  return (
    <>
      <div className="card">
        <div className="card-body p-0">
          {checked > 0 && (
            <div className="d-flex flex-row-reverse" id="button-group-action">
              {skkType === 'Administrasi' && (
                <>
                  <button type="submit" className="btn btn-flat-danger m-2" onClick={kePrint}>
                    Ke Print
                  </button>
                  <button type="button" className="btn btn-light m-2" onClick={batalkan}>
                    Batalkan
                  </button>
                </>
              )}
              {skkType === 'Print' && (
                <>
                  <button type="submit" className="btn btn-flat-danger m-2" onClick={kePenandatanganan}>
                    Ke Penandatanganan
                  </button>
                  <button type="button" className="btn btn-light m-2" onClick={keAdministrasi}>
                    Ke Administrasi
                  </button>
                </>
              )}
              {skkType === 'Penandatanganan' && (
                <>
                  <button type="submit" className="btn btn-flat-danger m-2" onClick={selesai}>
                    Selesai
                  </button>
                  <button type="button" className="btn btn-light m-2" onClick={kePrint}>
                    Ke Print
                  </button>
                </>
              )}
            </div>
          )}
          <div className="table-responsive border rounded">
            <table className="table">
              <thead className="bg-primary bg-opacity-10 text-primary">
                <tr>
                  <th> </th>
                  <th>Usulan Karyawan</th>
                  <th>Posisi Sekarang</th>
                  <th>Posisi Usulan</th>
                  <th>Progress</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                <SimpleList
                  data={skk}
                  renderItem={(item) => (
                    <SKKTableRow
                      key={item.id}
                      skk={item}
                      checked={checked}
                      setChecked={setChecked}
                    />
                  )}
                />
              </tbody>
            </table>
          </div>

        </div>
      </div>
      <TablePagination />
    </>
  );
};

SKKTable.propTypes = {
  skk: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      npk: PropTypes.string.isRequired,
      nama: PropTypes.string.isRequired,
      usulan: PropTypes.string.isRequired,
      tanggalDibuat: PropTypes.string.isRequired,
      tanggalUsulan: PropTypes.string.isRequired,
      noUsulan: PropTypes.string.isRequired,
      usulanDari: PropTypes.string.isRequired,
      mdg: PropTypes.string.isRequired,
      pbp: PropTypes.string.isRequired,
      posisiSekarang: PropTypes.shape({
        unitKerja: PropTypes.string.isRequired,
        jabatan: PropTypes.string.isRequired,
        jobGrade: PropTypes.string.isRequired,
        personalGrade: PropTypes.string.isRequired,
        atasan: PropTypes.string.isRequired,
        lokasi: PropTypes.string.isRequired
      }),
      posisiUsulan: PropTypes.shape({
        unitKerja: PropTypes.string.isRequired,
        jabatan: PropTypes.string.isRequired,
        jobGrade: PropTypes.string.isRequired,
        personalGrade: PropTypes.string.isRequired,
        atasan: PropTypes.string.isRequired,
        lokasi: PropTypes.string.isRequired
      })
    }).isRequired
  ).isRequired,
  skkType: PropTypes.string.isRequired
};
export default SKKTable;
