import uhc from '../../../dummy_data/uhc';
import { statusUHC } from '../../../utils/enum';
import UHCFilter from '../UHCFilter';
import CatatanRMPModal from '../CatatanRMPModal';
import LinkPengisianModal from './LinkPengisianModal';
import PenjadwalanTable from './PenjadwalanTable';

const PenjadwalanPage = () => {
  const { PENJADWALAN } = statusUHC;
  const uhcJobDescData = uhc.filter((item) => item.statusUHC === PENJADWALAN);
  return (
    <div className="page-content">
      <div className="content-wrapper">
        <div className="content-inner">
          <div className="page-header">
            <div className="page-header-content container d-lg-flex">
              <div className="d-flex">
                <h4 className="page-title mb-0">
                  Home -
                  {' '}
                  <span className="fw-normal">Dashboard</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="content container pt-0">
            <UHCFilter />
            <PenjadwalanTable
              uhcList={uhcJobDescData}
            />
          </div>
        </div>
      </div>
      <CatatanRMPModal />
      <LinkPengisianModal />
    </div>
  );
};

export default PenjadwalanPage;
