/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
const PosisiCard = () => (
  <div className="row">
    <div className="col-lg-6 pe-4">
      <div className="card">
        <div className="card-header bg-primary bg-opacity-10 text-primary fw-bold">
          Posisi Sekarang
        </div>
        <div className="card-body">
          <div className="mb-3">
            <label className="form-label">Unit Kerja</label>
            <input type="text" className="form-control" defaultValue="Unit Kerja Sekarang" disabled />
          </div>
          <div className="mb-3">
            <label className="form-label">Jabatan</label>
            <input type="text" className="form-control" defaultValue="Jabatan Sekarang" disabled />
          </div>
          <div className="row mb-3">
            <div className="col-4">
              <label className="form-label">Job Grade</label>
              <input type="text" className="form-control" defaultValue="JG " disabled />
            </div>
            <div className="col-4">
              <label className="form-label">Personal Grade</label>
              <input type="text" className="form-control" defaultValue="PG" disabled />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-lg-6 ps-4">
      <div className="card">
        <div className="card-header bg-primary bg-opacity-10 text-primary fw-bold">
          Posisi Usulan
        </div>
        <div className="card-body">
          <div className="mb-3">
            <label className="form-label">Unit Kerja</label>
            <input type="text" className="form-control" defaultValue="Unit Kerja Usulan" disabled />
          </div>
          <div className="mb-3">
            <label className="form-label">Jabatan</label>
            <input type="text" className="form-control" defaultValue="Jabatan Usulan" disabled />
          </div>
          <div className="row mb-3">
            <div className="col-4">
              <label className="form-label">Job Grade</label>
              <input type="text" className="form-control" defaultValue="JG " disabled />
            </div>
            <div className="col-4">
              <label className="form-label">Personal Grade</label>
              <input type="text" className="form-control" defaultValue="PG" disabled />
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
);

export default PosisiCard;
