import {
  CaretDown, Gear, SignOut
} from 'phosphor-react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const name = useSelector((state) => state.user.name);
  const dropdownSubmenu = () => {
    const menuClass = 'dropdown-menu';
    const submenuClass = 'dropdown-submenu';
    const menuToggleClass = 'dropdown-toggle';
    const disabledClass = 'disabled';
    const showClass = 'show';

    if (submenuClass) {
      document.querySelectorAll(`.${menuClass} .${submenuClass}:not(.${disabledClass}) .${menuToggleClass}`).forEach((link) => {
        link.addEventListener('click', (event) => {
          event.stopPropagation();
          event.preventDefault();

          link.closest(`.${submenuClass}`).classList.toggle(showClass);
          link.closest(`.${submenuClass}`).querySelectorAll(`:scope > .${menuClass}`).forEach((children) => {
            children.classList.toggle(showClass);
          });
          const siblings = link.parentNode.parentNode.children;
          const siblingsList = [...siblings];

          siblingsList.forEach((sibling) => {
            if (sibling !== link.parentNode) {
              sibling.classList.remove(showClass);
              sibling.querySelectorAll(`.${showClass}`).forEach((submenu) => {
                submenu.classList.remove(showClass);
              });
            }
          });
        });
      });

      document.querySelectorAll(`.${menuClass}`).forEach((link) => {
        if (!link.parentElement.classList.contains(submenuClass)) {
          link.parentElement.addEventListener('hidden.bs.dropdown', () => {
            link.querySelectorAll(`.${menuClass}.${showClass}`).forEach((children) => {
              children.classList.remove(showClass);
            });
          });
        }
      });
    }
  };

  useEffect(() => {
    dropdownSubmenu();
  }, []);

  return (
    <div className="navbar navbar-expand-xl navbar-static shadow">
      <div className="container-fluid">
        <div className="navbar-brand flex-1">
          <Link to="/" className="d-inline-flex align-items-center">
            <h4 className="text-dark mb-0">P S D M O</h4>
          </Link>
        </div>

        <div className="d-flex w-100 w-xl-auto overflow-auto overflow-xl-visible scrollbar-hidden border-top border-top-xl-0 order-1 order-xl-0 pt-2 pt-xl-0 mt-2 mt-xl-0">
          <ul className="nav gap-1 justify-content-center flex-nowrap flex-xl-wrap mx-auto">

            <li className="nav-item nav-item-dropdown-xl dropdown">
              <div className="navbar-nav-link dropdown-toggle rounded" data-bs-toggle="dropdown">
                Pengembangan Karir
                <CaretDown size={16} className="ms-1" />
              </div>

              <div className="dropdown-menu">
                <div className="dropdown-submenu dropdown-submenu-end">
                  <div className="dropdown-item dropdown-toggle">
                    RMP
                    <CaretDown size={16} className="ms-1 ms-auto" />
                  </div>
                  <div className="dropdown-menu">
                    <Link to="/rmp/usulan" className="dropdown-item rounded">Usulan</Link>
                    <Link to="/rmp/skk" className="dropdown-item rounded">SKK</Link>
                  </div>
                </div>
              </div>
            </li>

            <li className="nav-item nav-item-dropdown-xl dropdown">
              <div className="navbar-nav-link dropdown-toggle rounded" data-bs-toggle="dropdown">
                Organisasi &amp; Kompetisi
                <CaretDown size={16} className="ms-1" />
              </div>

              <div className="dropdown-menu">
                <Link to="/jobdesc" className="dropdown-item rounded">Job Description</Link>
                <div className="dropdown-submenu dropdown-submenu-end">
                  <div className="dropdown-item dropdown-toggle">
                    Uji Hard Competency
                    <CaretDown size={16} className="ms-1 ms-auto" />
                  </div>
                  <div className="dropdown-menu">
                    <Link to="/uhc/jobdesc" className="dropdown-item rounded">Jobdesc</Link>
                    <Link to="/uhc/penjadwalan" className="dropdown-item rounded">Penjadwalan</Link>
                    <Link to="/uhc/pelaksanaan" className="dropdown-item rounded">Pelaksanaan</Link>
                    <Link to="/uhc/hasil" className="dropdown-item rounded">Hasil</Link>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <ul className="nav gap-1 flex-xl-1 justify-content-end order-0 order-xl-1">

          <li className="nav-item nav-item-dropdown-xl dropdown">
            <div className="navbar-nav-link align-items-center rounded-pill p-1" data-bs-toggle="dropdown">
              <div className="status-indicator-container">
                <img src="../../../assets/images/demo/users/face11.jpg" className="w-32px h-32px rounded-pill" alt="" />
                <span className="status-indicator bg-success" />
              </div>
              <span className="d-none d-md-inline-block mx-md-2">{name}</span>
            </div>

            <div className="dropdown-menu dropdown-menu-end">
              <Link to="/setting" className="dropdown-item">
                <Gear className="me-2" />
                Pengaturan
              </Link>
              <div className="dropdown-divider" />
              <Link to="/" className="dropdown-item">
                <SignOut className="me-2" />
                Logout
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
