import {
  Tab, Tabs, TabList, TabPanel
} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import AdministrasiTab from './AdministrasiTab';
import CatatanSKKModal from './CatatanSKKModal';
import CatatanModal from './CatatanModal';
import PrintTab from './PrintTab';
import skk from '../../../../dummy_data/skk';
import { skkStatus } from '../../../../utils/enum';
import PenandatangananTab from './PenandatangananTab';
import UpdateSKKModal from './UpdateSKKModal';

const SKKPage = () => {
  const { ADMINISTRASI, PRINT, PENANDATANGANAN } = skkStatus;
  const administrasiData = skk.filter((item) => item.statusSKK === ADMINISTRASI);
  const printData = skk.filter((item) => item.statusSKK === PRINT);
  const penandatangananData = skk.filter((item) => item.statusSKK === PENANDATANGANAN);

  return (
    <div className="page-content">
      <div className="content-wrapper">
        <div className="content-inner">
          <div className="page-header">
            <div className="page-header-content container d-lg-flex">
              <div className="d-flex">
                <h4 className="page-title mb-0">
                  Home -
                  {' '}
                  <span className="fw-normal">Dashboard</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="content container pt-0">
            <Tabs>
              <TabList style={{ margin: '0', borderBottom: 'none' }}>
                <Tab style={{
                  border: '1px solid #aaa',
                  borderRadius: '5px 5px 0 0'
                }}
                >
                  Administrasi

                </Tab>
                <Tab style={{
                  border: '1px solid #aaa',
                  borderRadius: '5px 5px 0 0'
                }}
                >
                  Print
                </Tab>
                <Tab style={{
                  border: '1px solid #aaa',
                  borderRadius: '5px 5px 0 0'
                }}
                >
                  Penandatangan
                </Tab>
              </TabList>

              <TabPanel className="card" style={{ boxShadow: 'none', borderRadius: '0', marginBottom: '0' }}>
                <AdministrasiTab skk={administrasiData} />
              </TabPanel>
              <TabPanel className="card" style={{ boxShadow: 'none', borderRadius: '0', marginBottom: '0' }}>
                <PrintTab skk={printData} />
              </TabPanel>
              <TabPanel className="card" style={{ boxShadow: 'none', borderRadius: '0', marginBottom: '0' }}>
                <PenandatangananTab skk={penandatangananData} />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <CatatanModal />
      <CatatanSKKModal />
      <UpdateSKKModal />
    </div>
  );
};

export default SKKPage;
