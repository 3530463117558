import SimpleList from '../../Components/SimpleList/SimpleList';
import karyawanEvaluasi from '../../dummy_data/karyawanEvaluasi';
import EvaluasiBerjenjangTableRow from './EvaluasiBerjenjangTableRow';

const EvaluasiBerjenjangTable = () => (
  <div className="card">
    <div className="card-body">
      <div className="d-flex flex-row-reverse mb-3">
        <div className="col-auto">
          <input type="search" className="form-control" placeholder="Search" />
        </div>
      </div>
      <div className="table-responsive border rounded">
        <table className="table">
          <thead className="bg-primary bg-opacity-10 text-primary">
            <tr>
              <th>Karyawan</th>
              <th>Posisi</th>
              <th>Progress</th>
            </tr>
          </thead>
          <tbody>
            <SimpleList
              data={karyawanEvaluasi}
              renderItem={(item) => (
                <EvaluasiBerjenjangTableRow
                  key={item.id}
                  karyawanEvaluasi={item}
                />
              )}
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

export default EvaluasiBerjenjangTable;
