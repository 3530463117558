import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Breadcrumb from '../../../../Components/Breadcrumb/Breadcrumb';
import usulanRMP from '../../../../dummy_data/usulanRMP';
import UsulanForm from './UsulanForm';

const UsulanDetailPage = () => {
  const { usulanId } = useParams();
  const [usulan, setUsulan] = useState();

  const loadUsulan = () => {
    const index = usulanRMP.findIndex((item) => item.id.toString() === usulanId);
    setUsulan(usulanRMP[index]);
  };

  useEffect(() => {
    loadUsulan();
  });

  return (
    <div className="page-content">
      <div className="content-wrapper">
        <div className="content-inner">
          <Breadcrumb />
          <div className="content container pt-0">
            {usulan && <UsulanForm usulan={usulan} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsulanDetailPage;
