import {
  Tab, Tabs, TabList, TabPanel
} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { PlusCircle } from 'phosphor-react';
import { useNavigate } from 'react-router';
import UsulanTab from './UsulanTab';
import usulanRMP from '../../../../dummy_data/usulanRMP';
import { tipeUsulanRMP } from '../../../../utils/enum';
import CatatanModal from './CatatanModal';

const UsulanPage = () => {
  const navigate = useNavigate();
  const {
    ROTASI, MUTASI, PROMOSI, PROMOSI_BERTAHAP
  } = tipeUsulanRMP;
  const rotasiMutasiData = usulanRMP.filter((item) => [ROTASI, MUTASI].includes(item.tipeUsulan));
  const promosiData = usulanRMP.filter((item) => item.tipeUsulan === PROMOSI);
  const promosiBertahapData = usulanRMP.filter((item) => item.tipeUsulan === PROMOSI_BERTAHAP);

  const navigateToUsulanBaru = () => {
    navigate('/rmp/usulan/buat');
  };

  const navigateToUsulanBertahapBaru = () => {
    navigate('/rmp/usulan_bertahap/buat');
  };

  return (
    <div className="page-content">
      <div className="content-wrapper">
        <div className="content-inner">
          <div className="page-header">
            <div className="page-header-content container d-lg-flex">
              <div className="d-flex">
                <h4 className="page-title mb-0">
                  Home -
                  {' '}
                  <span className="fw-normal">Dashboard</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="content container pt-0">
            <button type="button" className="btn btn-flat-primary mb-3 me-2" onClick={navigateToUsulanBaru}>
              <PlusCircle size={18} className="me-2" />
              Usulan Baru
            </button>

            <button type="button" className="btn btn-flat-primary mb-3" onClick={navigateToUsulanBertahapBaru}>
              <PlusCircle size={18} className="me-2" />
              Usulan Promosi Bertahap Baru
            </button>
            <Tabs>
              <TabList style={{ margin: '0', borderBottom: 'none' }}>
                <Tab style={{
                  border: '1px solid #aaa',
                  borderRadius: '5px 5px 0 0'
                }}
                >
                  All

                </Tab>
                <Tab style={{
                  border: '1px solid #aaa',
                  borderRadius: '5px 5px 0 0'
                }}
                >
                  Rotasi &amp; Mutasi
                </Tab>
                <Tab style={{
                  border: '1px solid #aaa',
                  borderRadius: '5px 5px 0 0'
                }}
                >
                  Promosi
                </Tab>
                <Tab style={{
                  border: '1px solid #aaa',
                  borderRadius: '5px 5px 0 0'
                }}
                >
                  Promosi Bertahap
                </Tab>
                <Tab style={{
                  border: '1px solid #aaa',
                  borderRadius: '5px 5px 0 0'
                }}
                >
                  UHC
                </Tab>
              </TabList>

              <TabPanel className="card" style={{ boxShadow: 'none', borderRadius: '0', marginBottom: '0' }}>
                <UsulanTab usulanRMP={usulanRMP} tabType="all" />
              </TabPanel>
              <TabPanel className="card" style={{ boxShadow: 'none', borderRadius: '0', marginBottom: '0' }}>
                <UsulanTab usulanRMP={rotasiMutasiData} />
              </TabPanel>
              <TabPanel className="card" style={{ boxShadow: 'none', borderRadius: '0', marginBottom: '0' }}>
                <UsulanTab usulanRMP={promosiData} tabType="promosi" />
              </TabPanel>
              <TabPanel className="card" style={{ boxShadow: 'none', borderRadius: '0', marginBottom: '0' }}>
                <UsulanTab usulanRMP={promosiBertahapData} />
              </TabPanel>
              <TabPanel className="card" style={{ boxShadow: 'none', borderRadius: '0', marginBottom: '0' }}>
                <UsulanTab usulanRMP={usulanRMP} tabType="uhc" />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <CatatanModal />
    </div>
  );
};

export default UsulanPage;
