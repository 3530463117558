import PropTypes from 'prop-types';
import SKKPrintFilter from './SKKPrintFilter';
import SKKTable from './SKKTable';

const PrintTab = ({ skk }) => (
  <div className="card-body">
    <SKKPrintFilter />
    <SKKTable skk={skk} skkType="Print" />
  </div>
);

PrintTab.propTypes = {
  skk: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      npk: PropTypes.string.isRequired,
      nama: PropTypes.string.isRequired,
      usulan: PropTypes.string.isRequired,
      tanggalDibuat: PropTypes.string.isRequired,
      tanggalUsulan: PropTypes.string.isRequired,
      noUsulan: PropTypes.string.isRequired,
      usulanDari: PropTypes.string.isRequired,
      mdg: PropTypes.string.isRequired,
      pbp: PropTypes.string.isRequired,
      posisiSekarang: PropTypes.shape({
        unitKerja: PropTypes.string.isRequired,
        jabatan: PropTypes.string.isRequired,
        jobGrade: PropTypes.string.isRequired,
        personalGrade: PropTypes.string.isRequired,
        atasan: PropTypes.string.isRequired,
        lokasi: PropTypes.string.isRequired
      }),
      posisiUsulan: PropTypes.shape({
        unitKerja: PropTypes.string.isRequired,
        jabatan: PropTypes.string.isRequired,
        jobGrade: PropTypes.string.isRequired,
        personalGrade: PropTypes.string.isRequired,
        atasan: PropTypes.string.isRequired,
        lokasi: PropTypes.string.isRequired
      })
    }).isRequired
  ).isRequired
};

export default PrintTab;
