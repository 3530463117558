const uhc = [
  {
    id: 1,
    uhcId: 1,
    npk: '0603619',
    nama: 'Puguh Prasetyo',
    statusUHC: 'Jobdesc',
    tanggalPermintaanUHC: '2022-09-10',
    tanggalUsulan: '2022-08-11',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    statusJobdesc: 'Ada',
    catatanRMP: '',
    posisiSekarang: {
      unitKerja: 'Departemen Inovasi & Pengembangan Manajemen',
      jabatan: 'Staf Pratama II Pengelolaan Prosedur',
      jobGrade: '4B',
      personalGrade: '4B',
      atasan: 'AVP Pengelolaan Prosedur',
      lokasi: 'Bontang'
    },
    posisiUsulan: {
      unitKerja: 'Departemen Keamanan',
      jabatan: 'Staf Pratama II Administrasi & Perijinan',
      jobGrade: '4B',
      personalGrade: '4B',
      atasan: 'AVP Administrasi & Perijinan',
      lokasi: 'Bontang'
    }
  },
  {
    id: 2,
    uhcId: 2,
    npk: '0603619',
    nama: 'Puguh Prasetyo',
    statusUHC: 'Jobdesc',
    tanggalPermintaanUHC: '2022-09-10',
    tanggalUsulan: '2022-08-11',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    statusJobdesc: 'Tidak Ada',
    catatanRMP: '',
    posisiSekarang: {
      unitKerja: 'Departemen Inovasi & Pengembangan Manajemen',
      jabatan: 'Staf Pratama II Pengelolaan Prosedur',
      jobGrade: '4B',
      personalGrade: '4B',
      atasan: 'AVP Pengelolaan Prosedur',
      lokasi: 'Bontang'
    },
    posisiUsulan: {
      unitKerja: 'Departemen Keamanan',
      jabatan: 'Staf Pratama II Administrasi & Perijinan',
      jobGrade: '4B',
      personalGrade: '4B',
      atasan: 'AVP Administrasi & Perijinan',
      lokasi: 'Bontang'
    }
  },
  {
    id: 3,
    uhcId: 3,
    npk: '1404331',
    nama: 'Firsta Hardiyanto',
    statusUHC: 'Penjadwalan',
    tanggalPermintaanUHC: '2022-07-16',
    tanggalUsulan: '2022-06-29',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    statusJobdesc: 'Ada',
    catatanRMP: 'Catatan RMP 1',
    posisiSekarang: {
      unitKerja: 'Direktorat Operasi & Produksi',
      jabatan: 'Staf Muda I Direktur Operasi & Produksi Penugasan Sebagai Staf Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'Koordinator Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      lokasi: 'Bintuni'
    },
    posisiUsulan: {
      unitKerja: 'Direktorat Operasi & Produksi',
      jabatan: 'Staf Muda I Direktur Operasi & Produksi Penugasan Sebagai Staf Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'Koordinator Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      lokasi: 'Jakarta'
    }
  },
  {
    id: 4,
    uhcId: 4,
    npk: '1404331',
    nama: 'Firsta Hardiyanto',
    statusUHC: 'Penjadwalan',
    tanggalPermintaanUHC: '2022-07-16',
    tanggalUsulan: '2022-06-29',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    statusJobdesc: 'Ada',
    catatanRMP: 'Catatan RMP 2',
    picPenjadwalan: 'Bryan Alfadhori',
    tanggalPermintaanJadwal: '2022-08-13',
    linkPengisianJadwal: 'https://psdm.sdmpkt.com/uhc/penjadwalan/016jkxmzyirnplg7owfb',
    posisiSekarang: {
      unitKerja: 'Direktorat Operasi & Produksi',
      jabatan: 'Staf Muda I Direktur Operasi & Produksi Penugasan Sebagai Staf Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'Koordinator Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      lokasi: 'Bintuni'
    },
    posisiUsulan: {
      unitKerja: 'Direktorat Operasi & Produksi',
      jabatan: 'Staf Muda I Direktur Operasi & Produksi Penugasan Sebagai Staf Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'Koordinator Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      lokasi: 'Jakarta'
    }
  },
  {
    id: 5,
    uhcId: 5,
    npk: '1404331',
    nama: 'Firsta Hardiyanto',
    statusUHC: 'Penjadwalan',
    tanggalPermintaanUHC: '2022-07-16',
    tanggalUsulan: '2022-06-29',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    statusJobdesc: 'Ada',
    catatanRMP: 'Catatan RMP 3',
    picPenjadwalan: 'Bryan Alfadhori',
    tanggalPermintaanJadwal: '2022-08-13',
    tanggalPengisianJadwal: '2022-08-16',
    linkPengisianJadwal: 'https://psdm.sdmpkt.com/uhc/penjadwalan/016jkxmzyirnplg7owfb',
    posisiSekarang: {
      unitKerja: 'Direktorat Operasi & Produksi',
      jabatan: 'Staf Muda I Direktur Operasi & Produksi Penugasan Sebagai Staf Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'Koordinator Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      lokasi: 'Bintuni'
    },
    posisiUsulan: {
      unitKerja: 'Direktorat Operasi & Produksi',
      jabatan: 'Staf Muda I Direktur Operasi & Produksi Penugasan Sebagai Staf Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'Koordinator Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      lokasi: 'Jakarta'
    }
  },
  {
    id: 6,
    uhcId: 6,
    npk: '8803005',
    nama: 'Damarsasi',
    statusUHC: 'Pelaksanaan',
    tanggalPermintaanUHC: '2022-07-28',
    tanggalUsulan: '2022-07-18',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    statusJobdesc: 'Ada',
    catatanRMP: 'Catatan RMP 3',
    picPenjadwalan: 'Bryan Alfadhori',
    tanggalPermintaanJadwal: '2022-08-13',
    tanggalPengisianJadwal: '2022-08-16',
    posisiSekarang: {
      unitKerja: 'Departemen Administrasi Korporasi',
      jabatan: 'AVP Kearsipan & Administrasi',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'VP Administrasi Korporasi',
      lokasi: 'Bontang'
    },
    posisiUsulan: {
      unitKerja: 'Direktorat Keuangan & Umum',
      jabatan: 'Direktur PT Kaltim Nusa Etika',
      jobGrade: '2A',
      personalGrade: '2B',
      atasan: 'Direktur Keuangan & Umum',
      lokasi: 'Bontang'
    }
  },
  {
    id: 7,
    uhcId: 7,
    npk: '8803005',
    nama: 'Damarsasi',
    statusUHC: 'Pelaksanaan',
    tanggalPermintaanUHC: '2022-07-28',
    tanggalUsulan: '2022-07-18',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    statusJobdesc: 'Ada',
    catatanRMP: 'Catatan RMP 3',
    picPenjadwalan: 'Bryan Alfadhori',
    tanggalPermintaanJadwal: '2022-08-13',
    tanggalPengisianJadwal: '2022-08-16',
    picPelaksanaan: 'Ronald Weasley',
    undangan: {
      tanggalPembuatan: '2022-08-18',
      pembuatUndangan: 'Ronald Weasley'
    },
    posisiSekarang: {
      unitKerja: 'Departemen Administrasi Korporasi',
      jabatan: 'AVP Kearsipan & Administrasi',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'VP Administrasi Korporasi',
      lokasi: 'Bontang'
    },
    posisiUsulan: {
      unitKerja: 'Direktorat Keuangan & Umum',
      jabatan: 'Direktur PT Kaltim Nusa Etika',
      jobGrade: '2A',
      personalGrade: '2B',
      atasan: 'Direktur Keuangan & Umum',
      lokasi: 'Bontang'
    }
  },
  {
    id: 8,
    uhcId: 8,
    npk: '8803005',
    nama: 'Damarsasi',
    statusUHC: 'Hasil',
    tanggalPermintaanUHC: '2022-08-28',
    tanggalUsulan: '2022-08-18',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    statusJobdesc: 'Ada',
    catatanRMP: 'Catatan RMP 3',
    picPenjadwalan: 'Bryan Alfadhori',
    tanggalPermintaanJadwal: '2022-09-01',
    tanggalPengisianJadwal: '2022-09-05',
    picPelaksanaan: 'Ronald Weasley',
    undangan: {
      tanggalPembuatan: '2022-09-05',
      pembuatUndangan: 'Ronald Weasley'
    },
    posisiSekarang: {
      unitKerja: 'Departemen Administrasi Korporasi',
      jabatan: 'AVP Kearsipan & Administrasi',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'VP Administrasi Korporasi',
      lokasi: 'Bontang'
    },
    posisiUsulan: {
      unitKerja: 'Direktorat Keuangan & Umum',
      jabatan: 'Direktur PT Kaltim Nusa Etika',
      jobGrade: '2A',
      personalGrade: '2B',
      atasan: 'Direktur Keuangan & Umum',
      lokasi: 'Bontang'
    }
  },
  {
    id: 9,
    uhcId: 9,
    npk: '8803005',
    nama: 'Damarsasi',
    statusUHC: 'Hasil',
    tanggalPermintaanUHC: '2022-08-28',
    tanggalUsulan: '2022-08-18',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    statusJobdesc: 'Ada',
    catatanRMP: 'Catatan RMP 3',
    picPenjadwalan: 'Bryan Alfadhori',
    tanggalPermintaanJadwal: '2022-09-01',
    tanggalPengisianJadwal: '2022-09-05',
    picPelaksanaan: 'Ronald Weasley',
    undangan: {
      tanggalPembuatan: '2022-09-05',
      pembuatUndangan: 'Ronald Weasley'
    },
    suratPenilaianUHC: '1',
    tanggalPenilaianUHC: '2022-09-10',
    rekomendasiUHC: 'Direkomendasikan',
    hasilPenilaianUHC: '90%',
    statusUsulan: 'Lanjut',
    posisiSekarang: {
      unitKerja: 'Departemen Administrasi Korporasi',
      jabatan: 'AVP Kearsipan & Administrasi',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'VP Administrasi Korporasi',
      lokasi: 'Bontang'
    },
    posisiUsulan: {
      unitKerja: 'Direktorat Keuangan & Umum',
      jabatan: 'Direktur PT Kaltim Nusa Etika',
      jobGrade: '2A',
      personalGrade: '2B',
      atasan: 'Direktur Keuangan & Umum',
      lokasi: 'Bontang'
    }
  },
  {
    id: 10,
    uhcId: 10,
    npk: '8803005',
    nama: 'Damarsasi',
    statusUHC: 'Hasil',
    tanggalPermintaanUHC: '2022-08-28',
    tanggalUsulan: '2022-08-18',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    statusJobdesc: 'Ada',
    catatanRMP: 'Catatan RMP 3',
    picPenjadwalan: 'Bryan Alfadhori',
    tanggalPermintaanJadwal: '2022-09-01',
    tanggalPengisianJadwal: '2022-09-05',
    picPelaksanaan: 'Ronald Weasley',
    undangan: {
      tanggalPembuatan: '2022-09-05',
      pembuatUndangan: 'Ronald Weasley'
    },
    suratPenilaianUHC: '1',
    tanggalPenilaianUHC: '2022-09-10',
    rekomendasiUHC: 'Belum Direkomendasikan',
    hasilPenilaianUHC: '60%',
    statusUsulan: 'Gagal',
    posisiSekarang: {
      unitKerja: 'Departemen Administrasi Korporasi',
      jabatan: 'AVP Kearsipan & Administrasi',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'VP Administrasi Korporasi',
      lokasi: 'Bontang'
    },
    posisiUsulan: {
      unitKerja: 'Direktorat Keuangan & Umum',
      jabatan: 'Direktur PT Kaltim Nusa Etika',
      jobGrade: '2A',
      personalGrade: '2B',
      atasan: 'Direktur Keuangan & Umum',
      lokasi: 'Bontang'
    }
  }
];

export default uhc;
