import SimpleList from '../../../Components/SimpleList/SimpleList';
import TablePagination from '../../../Components/TablePagination/TablePagination';
import JobDescTableRow from './JobDescTableRow';

const JobDescTable = () => (
  <>
    <div className="card">
      <div className="card-body p-0">
        <div className="table-responsive border rounded">
          <table className="table">
            <thead className="bg-primary bg-opacity-10 text-primary">
              <tr>
                <th>Unit Kerja</th>
                <th>Detail</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              <SimpleList
                data={[{ id: 1 }]}
                renderItem={(item) => (
                  <JobDescTableRow
                    key={item.id}
                    uhc={item}
                  />
                )}
              />
            </tbody>
          </table>
        </div>

      </div>
    </div>
    <TablePagination />
  </>
);

export default JobDescTable;
