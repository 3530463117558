const ideImprovement = [
  {
    id: 1,
    idePokok: 'Peningkatan Efisiensi Organisasi',
    penjabaran: 'Langkah pertama yang akan saya lakukan adalah melakukan review dan evaluasi terhadap proses bisnis operasional perusahaan, dimulai dari proses bisnis utama yang menjadi prioritas dan kemudian untuk seluruh proses bisnis pendukung. Perlu dilakukan pemetaan dan evaluasi proses bisnis sehingga dapat diketahui apakah terdapat proses bisnis yang tumpang tindih antara satu fungsi dan lainnya, hingga pencapaian terhadap kinerja masing-masing proses bisnis tersebut'
  },
  {
    id: 2,
    idePokok: 'New Business and Partnership',
    penjabaran: 'Sejauh yang saya ketahui, selama ini perusahaan dominan bergerak di bidang pemenuhan tenaga kerja. Di satu sisi, sebenarnya perusahaan diuntungkan dari peraturan daerah untuk pemberdayaan tenaga kerja lokal. Namun demikian, organisasi juga dapat menciptakan model bisnis baru yaitu bukan hanya sekedar mensuplai tenaga kerja namun juga dapat menyediakan jasa headhunter'
  },
  {
    id: 3,
    idePokok: 'Digitalisasi Layanan One Stop Service',
    penjabaran: 'Peningkatan efisiensi perusahaan tentunya perlu didukung dengan penggunaan teknologi. Berdasarkan hasil pemetaan proses bisnis dan peluang dari risk based innovation, tentunya dapat dilakukan improvement di beberapa atau bahkan keseluruhan proses bisnis dengan digitalisasi.'
  }
];

export default ideImprovement;
