const RekomendasiCard = () => (

  <div className="card">
    <div className="card-header bg-primary bg-opacity-10 text-primary fw-bold">
      Rekomendasi
    </div>
    <div className="card-body">
      <div className="d-flex align-items-center">
        <input type="radio" name="dr_ls" id="dr_ls_c" />
        <label className="ms-2" htmlFor="dr_ls_c">Karyawan direkomendasikan untuk menduduki jabatan tersebut</label>
      </div>

      <div className="d-flex align-items-center mb-2">
        <input type="radio" name="dr_ls" id="dr_ls_u" />
        <label className="ms-2" htmlFor="dr_ls_u">Karyawan belum direkomendasikan untuk menduduki jabatan tersebut</label>
      </div>
    </div>
  </div>
);

export default RekomendasiCard;
