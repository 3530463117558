import SimpleList from '../../../../Components/SimpleList/SimpleList';
import TablePagination from '../../../../Components/TablePagination/TablePagination';
import usulanBertahapBaru from '../../../../dummy_data/usulanBertahapBaru';
import UsulanPromosiBertahapTableRow from './UsulanPromosiBertahapTableRow';

const UsulanPromosiBertahapTable = () => (
  <>
    <div className="card">
      <div className="card-body p-0">
        <div className="table-responsive border rounded">
          <table className="table">
            <thead className="bg-primary bg-opacity-10 text-primary">
              <tr>
                <th>Karyawan</th>
                <th>Posisi Sekarang</th>
                <th>Informasi</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              <SimpleList
                data={usulanBertahapBaru}
                renderItem={(item) => (
                  <UsulanPromosiBertahapTableRow
                    key={item.id}
                    usulan={item}
                  />
                )}
              />
            </tbody>
          </table>
        </div>

      </div>
    </div>
    <TablePagination />
  </>
);

export default UsulanPromosiBertahapTable;
