/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import { formatDateToInternationalFormat } from '../../../../utils/utils';
import UsulanAktifTable from './UsulanAktifTable';
import UsulanBaruForm from './PosisiForm';

const KaryawanDetail = ({ karyawan }) => {
  const { posisiUsulan } = karyawan;

  const timeDifference = () => {
    const today = new Date();
    const pbpSplit = karyawan.pbp.split('-');
    const pbpDate = new Date(pbpSplit[0], pbpSplit[1] - 1, pbpSplit[2]);
    const difference = (pbpDate.getTime() - today.getTime()) / 1000 / 3600 / 24 / 365;
    return Math.abs(difference);
  };

  return (
    <>
      <div className="card">
        <div className="card-header bg-primary bg-opacity-10 text-primary fw-bold">
          Data Karyawan
          {' '}
          {karyawan.nama}
        </div>
        <div className="card-body">
          {timeDifference() < 1 && (
            <div className="alert alert-danger border-0">
              <b>Peringatan!</b>
              {' '}
              Karyawan akan memasuki PBP kurang dari satu tahun
            </div>
          )}
          <div className="row mb-3">
            <label className="col-form-label col-2">Nama</label>
            <div className="col-4">
              <div className="input-group">
                <input type="text" className="form-control" value={karyawan.nama} disabled />
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-form-label col-2">PBP/Pensiun</label>
            <div className="col-4">
              <div className="input-group">
                <input type="text" className="form-control" value={formatDateToInternationalFormat(karyawan.pbp)} disabled />
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-form-label col-2">TMT G/TMT J</label>
            <div className="col-4">
              <div className="input-group">
                <input type="text" className="form-control" value={karyawan.nama} disabled />
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-form-label col-2">MDG/MDJ</label>
            <div className="col-4">
              <div className="input-group">
                {timeDifference() < 1
                  ? <input type="text" className="form-control" value={karyawan.mdg} disabled style={{ borderColor: 'red' }} />

                  : <input type="text" className="form-control" value={karyawan.mdg} disabled />}
              </div>
            </div>
          </div>

        </div>
      </div>
      {/* {posisiUsulan
      && <UsulanAktifTable usulan={karyawan} />} */}
      <UsulanBaruForm posisiSekarang={karyawan.posisiSekarang} />
    </>
  );
};

KaryawanDetail.defaultProps = {
  karyawan: {
    posisiUsulan: {}
  }
};

KaryawanDetail.propTypes = {
  karyawan: PropTypes.shape({
    nama: PropTypes.string.isRequired,
    mdg: PropTypes.string.isRequired,
    pbp: PropTypes.string.isRequired,
    posisiSekarang: PropTypes.shape({
      unitKerja: PropTypes.string.isRequired,
      jabatan: PropTypes.string.isRequired,
      jobGrade: PropTypes.string.isRequired,
      personalGrade: PropTypes.string.isRequired,
      atasan: PropTypes.string.isRequired,
      lokasi: PropTypes.string.isRequired
    }),
    posisiUsulan: PropTypes.shape({})
  })
};

export default KaryawanDetail;
