/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import { Printer } from 'phosphor-react';
import Select from 'react-select';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import withReactContent from 'sweetalert2-react-content';

const PersyaratanRotasiMutasiForm = ({ persyaratan }) => {
  const MySwal = withReactContent(Swal);

  const batalkan = () => {
    MySwal.fire({
      icon: 'warning',
      title: 'Apakah anda yakin?',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonText: 'Ya',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-flat-primary',
        cancelButton: 'btn btn-light '
      }
    });
  };

  const buatSuratKetetapanPegawai = () => {
    MySwal.fire({
      icon: 'warning',
      title: 'Apakah anda yakin?',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonText: 'Ya',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-flat-primary',
        cancelButton: 'btn btn-light '
      }
    });
  };
  const statusUsulanOptions = [
    { value: 'Administrasi', label: 'Administrasi' },
    { value: 'Persetujuan BoD PKT', label: 'Persetujuan BoD PKT' },
    { value: 'Persetujuan PI', label: 'Persetujuan PI' },
    { value: 'UHC', label: 'UHC' },
    { value: 'SKK', label: 'SKK' }
  ];

  const persetujuanBoDPKTOptions = [
    { value: 'Belum', label: 'Belum' },
    { value: 'Proses Administrasi', label: 'Proses Administrasi' },
    { value: 'Wawancara', label: 'Wawancara' },
    { value: 'Selesai', label: 'Selesai' },
    { value: 'Tidak Diperlukan', label: 'Tidak Diperlukan' }
  ];

  const persetujuanPIOptions = [
    { value: 'Belum', label: 'Belum' },
    { value: 'Proses', label: 'Proses' },
    { value: 'Selesai', label: 'Selesai' },
    { value: 'Tidak Diperlukan', label: 'Tidak Diperlukan' }
  ];

  return (
    <div className="card">
      <div className="card-header bg-primary bg-opacity-10 text-primary fw-bold">
        Persyaratan
      </div>

      <div className="card-body">
        <div className="row mb-3">
          <label className="col-form-label col-2">Status Usulan</label>
          <div className="col-5">
            <Select
              className="basic-single bg-transparent"
              classNamePrefix="select"
              defaultValue={{ value: persyaratan.statusUsulan, label: persyaratan.statusUsulan }}
              options={statusUsulanOptions}
              placeholder="Pilih status"
            />
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-form-label col-2">Persetujuan BoD PKT</label>
          <div className="col-auto">
            <Select
              className="basic-single bg-transparent"
              classNamePrefix="select"
              options={persetujuanBoDPKTOptions}
              defaultValue={{
                value: persyaratan.persetujuanBoDPKT,
                label: persyaratan.persetujuanBoDPKT
              }}
              placeholder="Pilih"
            />
            <hr />
            <button type="button" className="btn btn-outline-primary btn-sm me-2">
              <Printer className="me-2" size={16} />
              Form D1
            </button>
            <button type="button" className="btn btn-outline-primary btn-sm me-2">
              <Printer className="me-2" size={16} />
              Form D2
            </button>
            <button type="button" className="btn btn-outline-primary btn-sm me-2">
              <Printer className="me-2" size={16} />
              Form D3
            </button>
            <button type="button" className="btn btn-outline-primary btn-sm">
              <Printer className="me-2" size={16} />
              Form D4
            </button>
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-form-label col-2">Persetujuan PI</label>
          <div className="col-5">
            <Select
              className="basic-single bg-transparent"
              classNamePrefix="select"
              options={persetujuanPIOptions}
              defaultValue={{ value: persyaratan.persetujuanPI, label: persyaratan.persetujuanPI }}
              placeholder="Pilih"
            />
          </div>
        </div>
        {persyaratan.suratKetetapanPegawai === '' ? (
          <div className="row mb-3">
            <label className="col-form-label col-2">Surat Ketetapan Kepegawaian</label>
            <div className="col-5">
              <button type="button" className="btn btn-sm btn-primary" onClick={buatSuratKetetapanPegawai}>
                Buat Surat Ketetapan Pegawai
              </button>
            </div>
          </div>
        )
          : (
            <div className="row mb-3 align-items-start">
              <label className="col-form-label col-2">Surat Ketetapan Kepegawaian</label>
              <div className="col-5 d-flex">
                <input className="form-control" value={persyaratan.suratKetetapanPegawai} disabled />
                <button type="button" className="btn btn-sm btn-danger ms-2" onClick={batalkan}>Batalkan</button>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

PersyaratanRotasiMutasiForm.defaultProps = {
  persyaratan: {
    persetujuanBoDPKT: '',
    persetujuanPI: ''
  }
};

PersyaratanRotasiMutasiForm.propTypes = {
  persyaratan: PropTypes.shape({
    statusUsulan: PropTypes.string.isRequired,
    persetujuanBoDPKT: PropTypes.string,
    persetujuanPI: PropTypes.string,
    suratKetetapanPegawai: PropTypes.string.isRequired
  })
};

export default PersyaratanRotasiMutasiForm;
