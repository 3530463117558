const karyawanUsulanBaru = {
  nama: 'Muhammad Yamani',
  mdg: '2.33 / 2 tahun 3 bulan 29 hari',
  pbp: '2022-04-11',
  posisiSekarang: {
    unitKerja: 'Departemen Riset',
    jabatan: 'Staf Madya II Riset',
    jobGrade: '2B',
    personalGrade: '2B',
    atasan: 'VP Riset',
    lokasi: 'Bontang'
  }
};

export default karyawanUsulanBaru;
