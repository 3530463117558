import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { formatDateToInternationalFormat } from '../../../utils/utils';

const PelaksanaanTableRow = ({ uhc }) => {
  const MySwal = withReactContent(Swal);

  const keHasil = () => {
    MySwal.fire({
      icon: 'warning',
      title: 'Apakah anda yakin?',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonText: 'Ya',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-flat-primary',
        cancelButton: 'btn btn-light '
      }
    });
  };

  return (
    <tr>
      <td className="cell-align-top">
        <p className="fs-sm mb-0">No Usulan</p>
        <p>{uhc.noUsulan}</p>
        <p className="fs-sm mb-0">NPK</p>
        <p>{uhc.npk}</p>
        <p className="fs-sm mb-0">Nama</p>
        <p>{uhc.nama}</p>
      </td>
      <td className="cell-align-top">
        <p className="fs-sm mb-0">Unit Kerja</p>
        <p>{uhc.posisiSekarang.unitKerja}</p>
        <p className="fs-sm mb-0">Jabatan</p>
        <p className="text-truncate w-120px" data-tip data-for={`tooltip-jabatan-sekarang-${uhc.id}`}>
          {uhc.posisiSekarang.jabatan}
        </p>
        <ReactTooltip id={`tooltip-jabatan-sekarang-${uhc.id}`} className="wmax-120px text-center">
          {uhc.posisiSekarang.jabatan}
        </ReactTooltip>
        <p className="fs-sm mb-0">JG | PG</p>
        <p>
          {uhc.posisiSekarang.jobGrade}
          {' '}
          |
          {' '}
          {uhc.posisiSekarang.personalGrade}
        </p>
        <p className="fs-sm mb-0">Lokasi</p>
        <p>{uhc.posisiSekarang.lokasi}</p>
      </td>
      <td className="cell-align-top">
        <p className="fs-sm mb-0">Unit Kerja</p>
        <p>{uhc.posisiUsulan.unitKerja}</p>
        <p className="fs-sm mb-0">Jabatan</p>
        <p className="text-truncate w-120px" data-tip data-for={`tooltip-jabatan-usulan-${uhc.id}`}>{uhc.posisiUsulan.jabatan}</p>
        <ReactTooltip id={`tooltip-jabatan-usulan-${uhc.id}`} className="wmax-120px text-center">
          {uhc.posisiUsulan.jabatan}
        </ReactTooltip>
        <p className="fs-sm mb-0">JG | PG</p>
        <p>
          {uhc.posisiUsulan.jobGrade}
          {' '}
          |
          {' '}
          {uhc.posisiUsulan.personalGrade}
        </p>
        <p className="fs-sm mb-0">Lokasi</p>
        <p>{uhc.posisiUsulan.lokasi}</p>
      </td>
      <td className="cell-align-top">
        <p className="fs-sm mb-0">PIC Penjadwalan</p>
        <p>{uhc.picPenjadwalan}</p>
        <p className="fs-sm mb-0">Tanggal Permintaan Jadwal</p>
        <p>{formatDateToInternationalFormat(uhc.tanggalPermintaanJadwal)}</p>
        <p className="fs-sm mb-0">Tanggal Pengisian Jadwal</p>
        <p>{formatDateToInternationalFormat(uhc.tanggalPengisianJadwal)}</p>
        { uhc.picPelaksanaan && (
          <>
            <p className="fs-sm mb-0">PIC Pelaksanaan</p>
            <p>{uhc.picPelaksanaan}</p>
          </>
        )}
        <button
          type="button"
          className="btn btn-flat-secondary btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#modal-catatan-rmp"
        >
          Catatan RMP
        </button>
        <br />
      </td>
      <td className="cell-action cell-align-top text-center">
        {
        uhc.id === 6 ? (
          <button
            type="button"
            className="btn btn-flat-secondary btn-sm"
            data-bs-toggle="modal"
            data-bs-target="#modal-detail-pelaksanaan"
          >
            Detail Pelaksanaan
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-flat-secondary btn-sm"
            data-bs-toggle="modal"
            data-bs-target="#modal-detail-pelaksanaan-2"
          >
            Detail Pelaksanaan
          </button>
        )
      }
        {uhc.undangan && (
        <>
          <br />
          <button
            type="button"
            className="btn btn-flat-secondary btn-sm mt-2"
            data-bs-toggle="modal"
            data-bs-target="#modal-dokumen-pelaksanaan"
          >
            Dokumen Pelaksanaan
          </button>
          <br />
          <button type="button" className="btn btn-flat-danger btn-sm mt-2" onClick={keHasil}>
            Ke Hasil
          </button>
        </>
        )}
      </td>
    </tr>
  );
};

PelaksanaanTableRow.defaultProps = {
  uhc: {
    picPelaksanaan: '',
    undangan: {}
  }
};

PelaksanaanTableRow.propTypes = {
  uhc: PropTypes.shape({
    id: PropTypes.number.isRequired,
    npk: PropTypes.string.isRequired,
    nama: PropTypes.string.isRequired,
    tanggalPermintaanUHC: PropTypes.string.isRequired,
    tanggalUsulan: PropTypes.string.isRequired,
    noUsulan: PropTypes.string.isRequired,
    usulanDari: PropTypes.string.isRequired,
    statusJobdesc: PropTypes.string.isRequired,
    catatanRMP: PropTypes.string.isRequired,
    tanggalPermintaanJadwal: PropTypes.string.isRequired,
    tanggalPengisianJadwal: PropTypes.string.isRequired,
    picPenjadwalan: PropTypes.string.isRequired,
    picPelaksanaan: PropTypes.string,
    undangan: PropTypes.shape({}),
    posisiSekarang: PropTypes.shape({
      unitKerja: PropTypes.string.isRequired,
      jabatan: PropTypes.string.isRequired,
      jobGrade: PropTypes.string.isRequired,
      personalGrade: PropTypes.string.isRequired,
      atasan: PropTypes.string.isRequired,
      lokasi: PropTypes.string.isRequired
    }),
    posisiUsulan: PropTypes.shape({
      unitKerja: PropTypes.string.isRequired,
      jabatan: PropTypes.string.isRequired,
      jobGrade: PropTypes.string.isRequired,
      personalGrade: PropTypes.string.isRequired,
      atasan: PropTypes.string.isRequired,
      lokasi: PropTypes.string.isRequired
    })
  })
};

export default PelaksanaanTableRow;
