/* eslint-disable no-unused-vars */
import uhc from '../../../dummy_data/uhc';
import SimpleList from '../../../Components/SimpleList/SimpleList';
import TablePagination from '../../../Components/TablePagination/TablePagination';
import PelaksanaanTableRow from './PelaksanaanTableRow';
import { statusUHC } from '../../../utils/enum';

const PelaksanaanTable = () => {
  const { PELAKSANAAN } = statusUHC;
  const uhcJobDescData = uhc.filter((item) => item.statusUHC === PELAKSANAAN);
  return (
    <>
      <div className="card">
        <div className="card-body p-0">
          <div className="table-responsive border rounded">
            <table className="table">
              <thead className="bg-primary bg-opacity-10 text-primary">
                <tr>
                  <th>Usulan Karyawan</th>
                  <th>Posisi Sekarang</th>
                  <th>Posisi Usulan</th>
                  <th>Progress</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                <SimpleList
                  data={uhcJobDescData}
                  renderItem={(item) => (
                    <PelaksanaanTableRow
                      key={item.id}
                      uhc={item}
                    />
                  )}
                />
              </tbody>
            </table>
          </div>

        </div>
      </div>
      <TablePagination />
    </>
  );
};

export default PelaksanaanTable;
