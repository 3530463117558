/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

import Select from 'react-select';

const JobDescDetailFilter = () => {
  const WFOptions = [
    { value: 'Struktural', label: 'Struktural' },
    { value: 'Fungsional', label: 'Fungsional' }
  ];

  const statusOptions = [
    { value: 'Tersedia', label: 'Tersedia' },
    { value: 'Belum Tersedia', label: 'Belum Tersedia' }
  ];

  const formatOptions = [
    { value: 'Baru', label: 'Baru' },
    { value: 'Lama', label: 'Lama' }
  ];

  const keteranganOptions = [
    { value: 'TTD', label: 'TTD' },
    { value: 'Draft', label: 'Draft' }
  ];

  return (
    <div className="card">
      <div className="card-header bg-primary bg-opacity-10 text-primary fw-bold">
        <div className="mb-0">Filter Jabatan</div>
      </div>

      <div className="card-body">
        <div className="row">
          <div className="col-lg-3 mb-3">
            <label className="form-label">WF</label>
            <Select
              className="basic-single bg-transparent"
              classNamePrefix="select"
              options={WFOptions}
              defaultValue={null}
              placeholder="Pilih"
            />
          </div>
          <div className="col-lg-3 mb-3">
            <label className="form-label">Status</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              options={statusOptions}
              defaultValue={null}
              placeholder="Pilih"
            />
          </div>
          <div className="col-lg-3 mb-3">
            <label className="form-label">Format</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              options={formatOptions}
              defaultValue={null}
              placeholder="Pilih"
            />
          </div>
          <div className="col-lg-3 mb-3">
            <label className="form-label">Keterangan</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              options={keteranganOptions}
              defaultValue={null}
              placeholder="Pilih"
            />
          </div>
        </div>
        <div className="d-flex flex-row-reverse">
          <button type="button" className="btn btn-primary my-1 me-2">Filter</button>
          <button type="button" className="btn btn-light my-1 me-2">Reset</button>
        </div>
      </div>
    </div>
  );
};

export default JobDescDetailFilter;
