import {
  Moon
} from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import { changeNightMode } from '../../redux/userSlice';

const ThemeConfigurator = () => {
  const isNightMode = useSelector((state) => state.user.isNightMode);
  const dispatch = useDispatch();

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex flex-fill my-1">
          <div className="form-check-label d-flex me-2">
            <Moon size={24} className="me-3" />
            <div>
              <span className="fw-bold">Night Mode</span>
              <div className="fs-sm text-muted">Switch to night mode</div>
            </div>
          </div>
          <label className="form-check form-switch form-check-reverse ms-auto">
            <input type="checkbox" className="form-check-input" onChange={() => dispatch(changeNightMode())} defaultChecked={isNightMode} />
          </label>
        </div>
      </div>
    </div>
  );
};

export default ThemeConfigurator;
