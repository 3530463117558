const usulanBertahapBaru = [
  {
    id: 1,
    npk: '0603619',
    nama: 'Puguh Prasetyo',
    tmtj: '2010-10-10',
    tmtg: '2010-10-10',
    mdj: '2.33 / 2 tahun 3 bulan 29 hari',
    mdg: '2.33 / 2 tahun 3 bulan 29 hari',
    pbp: '2042-04-11',
    posisiSekarang: {
      unitKerja: 'Departemen Inovasi & Pengembangan Manajemen',
      jabatan: 'Staf Pratama II Pengelolaan Prosedur',
      jobGrade: '4B',
      personalGrade: '4B',
      atasan: 'AVP Pengelolaan Prosedur',
      lokasi: 'Bontang'
    }
  }
];

export default usulanBertahapBaru;
