/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/control-has-associated-label */

const UpdateSKKModal = () => (
  <div id="modal-update-skk" className="modal fade" tabIndex="-1">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Update Informasi SKK</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" />
        </div>

        <div className="modal-body">
          <div className="row">
            <div className="col-lg-5 mb-3">
              <label className="form-label">NPK</label>
              <input type="text" className="form-control" disabled />
            </div>
            <div className="col-lg-5 mb-3">
              <label className="form-label">Nama Karyawan</label>
              <input type="text" className="form-control" disabled />
            </div>
          </div>
          <div className="row">
            <div className="col-6" style={{ borderRight: '1px solid #333' }}>
              <h3>Posisi Sekarang</h3>
              <div className="mb-3">
                <label className="form-label">Unit Kerja</label>
                <input type="text" className="form-control" disabled />
              </div>
              <div className="mb-3">
                <label className="form-label">Jabatan</label>
                <input type="text" className="form-control" disabled />
              </div>
              <div className="mb-3">
                <label className="form-label">JG/PG</label>
                <input type="text" className="form-control" disabled />
              </div>
            </div>
            <div className="col-6">
              <h3>Posisi Usulan</h3>
              <div className="mb-3">
                <label className="form-label">Unit Kerja</label>
                <input type="text" className="form-control" disabled />
              </div>
              <div className="mb-3">
                <label className="form-label">Jabatan</label>
                <input type="text" className="form-control" disabled />
              </div>
              <div className="mb-3">
                <label className="form-label">JG/PG</label>
                <input type="text" className="form-control" disabled />
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-5 mb-3">
              <label className="form-label">TMT</label>
              <input type="date" className="form-control" />
            </div>
            <div className="col-lg-5 mb-3">
              <label className="form-label">Position ID</label>
              <input type="text" className="form-control" />
            </div>
          </div>
          <div className="col-12 mb-3">
            <label className="form-label">Catatan SKK</label>
            <textarea className="form-control" />
          </div>
        </div>

        <div className="modal-footer">
          <button type="button" className="btn btn-link" data-bs-dismiss="modal">Batal</button>
          <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Simpan</button>
        </div>
      </div>
    </div>
  </div>
);

export default UpdateSKKModal;
