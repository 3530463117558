import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { formatDateToInternationalFormat, usulanBadgeColor } from '../../../../utils/utils';

const SKKTableRow = ({ skk, checked, setChecked }) => {
  const onChecked = (event) => {
    if (event.target.checked) {
      setChecked(checked + 1);
    } else {
      setChecked(checked - 1);
    }
  };

  return (
    <tr>
      <td className="cell-align-top">
        <input type="checkbox" id="dc_li_c" onChange={onChecked} />
      </td>
      <td className="cell-align-top">
        <span className={`badge ${usulanBadgeColor(skk.usulan)} me-2 w-100`}>{skk.usulan}</span>
        <p className="fs-sm mb-0">No Usulan:</p>
        <p>{skk.noUsulan}</p>
        <p className="fs-sm mb-0">NPK:</p>
        <p className="mb-0">{skk.npk}</p>
        <p>{skk.nama}</p>
      </td>
      <td className="cell-align-top">
        <p className="fs-sm mb-0">Unit Kerja</p>
        <p>{skk.posisiSekarang.unitKerja}</p>
        <p className="fs-sm mb-0">Jabatan</p>
        <p className="text-truncate w-120px" data-tip data-for={`tooltip-jabatan-sekarang-${skk.id}`}>
          {skk.posisiSekarang.jabatan}
        </p>
        <ReactTooltip id={`tooltip-jabatan-sekarang-${skk.id}`} className="wmax-160px text-center">
          {skk.posisiSekarang.jabatan}
        </ReactTooltip>
        <p className="fs-sm mb-0">JG | PG</p>
        <p>
          {skk.posisiSekarang.jobGrade}
          {' '}
          |
          {' '}
          {skk.posisiSekarang.personalGrade}
        </p>
        <p className="fs-sm mb-0">Lokasi</p>
        <p>{skk.posisiSekarang.lokasi}</p>
      </td>
      <td className="cell-align-top">
        <p className="fs-sm mb-0">Unit Kerja</p>
        <p>{skk.posisiUsulan.unitKerja}</p>
        <p className="fs-sm mb-0">Jabatan</p>
        <p className="text-truncate w-120px" data-tip data-for={`tooltip-jabatan-usulan-${skk.id}`}>{skk.posisiUsulan.jabatan}</p>
        <ReactTooltip id={`tooltip-jabatan-usulan-${skk.id}`} className="wmax-160px text-center">
          {skk.posisiUsulan.jabatan}
        </ReactTooltip>
        <p className="fs-sm mb-0">JG | PG</p>
        <p>
          {skk.posisiUsulan.jobGrade}
          {' '}
          |
          {' '}
          {skk.posisiUsulan.personalGrade}
        </p>
        <p className="fs-sm mb-0">Lokasi</p>
        <p>{skk.posisiUsulan.lokasi}</p>
      </td>
      <td className="cell-align-top">
        <p className="fs-sm mb-0">TMT</p>
        <p>{formatDateToInternationalFormat(skk.tmt)}</p>
        <p className="fs-sm mb-0">Position ID</p>
        <p>{skk.positionId}</p>
        <button
          type="button"
          className="btn btn-flat-secondary btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#modal-catatan"
        >
          Catatan
        </button>
        <button
          type="button"
          className="btn btn-flat-secondary btn-sm mt-2"
          data-bs-toggle="modal"
          data-bs-target="#modal-catatan-skk"
        >
          Catatan SKK
        </button>
      </td>
      <td className="cell-action cell-align-top">
        <button
          type="button"
          className="btn btn-flat-primary btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#modal-update-skk"
        >
          Update
        </button>
      </td>
    </tr>
  );
};

SKKTableRow.propTypes = {
  skk: PropTypes.shape({
    id: PropTypes.number.isRequired,
    npk: PropTypes.string.isRequired,
    nama: PropTypes.string.isRequired,
    usulan: PropTypes.string.isRequired,
    tanggalDibuat: PropTypes.string.isRequired,
    tanggalUsulan: PropTypes.string.isRequired,
    noUsulan: PropTypes.string.isRequired,
    usulanDari: PropTypes.string.isRequired,
    mdg: PropTypes.string.isRequired,
    pbp: PropTypes.string.isRequired,
    tmt: PropTypes.string.isRequired,
    positionId: PropTypes.string.isRequired,
    posisiSekarang: PropTypes.shape({
      unitKerja: PropTypes.string.isRequired,
      jabatan: PropTypes.string.isRequired,
      jobGrade: PropTypes.string.isRequired,
      personalGrade: PropTypes.string.isRequired,
      atasan: PropTypes.string.isRequired,
      lokasi: PropTypes.string.isRequired
    }),
    posisiUsulan: PropTypes.shape({
      unitKerja: PropTypes.string.isRequired,
      jabatan: PropTypes.string.isRequired,
      jobGrade: PropTypes.string.isRequired,
      personalGrade: PropTypes.string.isRequired,
      atasan: PropTypes.string.isRequired,
      lokasi: PropTypes.string.isRequired
    })
  }).isRequired,
  checked: PropTypes.number.isRequired,
  setChecked: PropTypes.func.isRequired
};

export default SKKTableRow;
