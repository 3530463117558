import { tipeUsulanRMP } from './enum';

export const setTheme = (isNightMode) => {
  const st = document.documentElement;
  if (isNightMode) {
    return st.setAttribute('data-color-theme', 'dark');
  }
  return st.removeAttribute('data-color-theme');
};

export const formatDateTimeToInternationalFormat = (stringDate) => {
  const INTERNATIONAL_FORMAT = 'en-GB';
  const IN_NUMBER_FORMAT = 'numeric';
  const IN_STRING_FORMAT = 'short';
  const date = new Date(stringDate);
  const dateOption = {
    day: IN_NUMBER_FORMAT,
    month: IN_STRING_FORMAT,
    year: IN_NUMBER_FORMAT,
    hour: IN_NUMBER_FORMAT,
    minute: IN_NUMBER_FORMAT
  };

  return new Intl.DateTimeFormat(INTERNATIONAL_FORMAT, dateOption).format(date);
};

export const formatDateToInternationalFormat = (stringDate) => {
  const INTERNATIONAL_FORMAT = 'en-GB';
  const IN_NUMBER_FORMAT = 'numeric';
  const IN_STRING_FORMAT = 'short';
  const date = new Date(stringDate);
  const dateOption = {
    day: IN_NUMBER_FORMAT,
    month: IN_STRING_FORMAT,
    year: IN_NUMBER_FORMAT
  };

  return new Intl.DateTimeFormat(INTERNATIONAL_FORMAT, dateOption).format(date);
};

export const usulanBadgeColor = (tipeUsulan) => {
  const {
    ROTASI, MUTASI, PROMOSI, PROMOSI_BERTAHAP
  } = tipeUsulanRMP;
  if (tipeUsulan === ROTASI) {
    return 'bg-indigo';
  }
  if (tipeUsulan === MUTASI) {
    return 'bg-teal';
  }
  if (tipeUsulan === PROMOSI) {
    return 'bg-pink';
  }
  if (tipeUsulan === PROMOSI_BERTAHAP) {
    return 'bg-warning';
  }
  return '';
};

export default {
  setTheme,
  formatDateTimeToInternationalFormat,
  formatDateToInternationalFormat
};
