/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

const FormPenilaianPejabatSementara = () => (
  <div className="card">
    <div className="card-header bg-primary bg-opacity-10 text-primary fw-bold">
      Form Penilaian Pejabat Sementara
    </div>
    <div className="card-body">
      <div className="row mb-3">
        <label className="col-form-label col-2">Surat Permintaan Evaluasi</label>
        <div className="col-4">
          <div className="input-group">
            <input type="text" className="form-control" value="123" disabled />
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <label className="col-form-label col-2">Tanggal Permintaan Evaluasi</label>
        <div className="col-4">
          <div className="input-group">
            <input type="text" className="form-control" value="20 November 2022" disabled />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default FormPenilaianPejabatSementara;
