/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';

const IdeCard = ({ ide }) => (
  <div className="card">
    <div className="card-header bg-primary bg-opacity-10 text-primary fw-bold">
      Idea
      {' '}
      {ide.id}
      <input type="text" className="form-control mt-2" value={ide.idePokok} />
    </div>
    <div className="card-body">
      <textarea type="text" className="form-control" value={ide.penjabaran} rows={4} />
    </div>
  </div>
);

IdeCard.propTypes = {
  ide: PropTypes.shape({
    id: PropTypes.number.isRequired,
    idePokok: PropTypes.string.isRequired,
    penjabaran: PropTypes.string.isRequired
  }).isRequired
};

export default IdeCard;
