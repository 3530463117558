import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import ReactTooltip from 'react-tooltip';

const EvaluasiBerjenjangTableRow = ({ karyawanEvaluasi }) => {
  const navigate = useNavigate();

  const navigateToPrint = () => {
    window.open('https://prototype.sdmpkt.com/template/rmp/usulan/update/promosibertahap/pjs/print', '_blank', 'noopener, noreferrer');
  };

  const navigateToDetail = () => {
    navigate('/evaluasi_berjenjang/detail');
  };

  return (
    <tr>
      <td className="cell-align-top">
        <p className="fs-sm mb-0">NPK</p>
        <p>{karyawanEvaluasi.npk}</p>
        <p className="fs-sm mb-0">Nama</p>
        <p>{karyawanEvaluasi.nama}</p>
        <p className="fs-sm mb-0">PBP/Pensiun</p>
        <p>{karyawanEvaluasi.pbp}</p>
      </td>
      <td className="cell-align-top">
        <p className="fs-sm mb-0">Unit Kerja</p>
        <p>{karyawanEvaluasi.posisiSekarang.unitKerja}</p>
        <p className="fs-sm mb-0">Jabatan</p>
        <p className="text-truncate w-120px" data-tip data-for={`tooltip-jabatan-sekarang-${karyawanEvaluasi.id}`}>
          {karyawanEvaluasi.posisiSekarang.jabatan}
        </p>
        <ReactTooltip id={`tooltip-jabatan-sekarang-${karyawanEvaluasi.id}`} className="wmax-160px text-center">
          {karyawanEvaluasi.posisiSekarang.jabatan}
        </ReactTooltip>
        <p className="fs-sm mb-0">JG | PG</p>
        <p>
          {karyawanEvaluasi.posisiSekarang.jobGrade}
          {' '}
          |
          {' '}
          {karyawanEvaluasi.posisiSekarang.personalGrade}
        </p>
      </td>
      <td className="cell-align-top">
        <p className="fs-sm mb-0">Surat PSDMO</p>
        <p>{karyawanEvaluasi.suratPSDMO}</p>
        <button type="button" className="btn btn-primary btn-sm mb-2" onClick={navigateToDetail}>
          Form Evaluasi
        </button>
        <br />
        <button type="button" className="btn btn-flat-primary btn-sm me-2" onClick={navigateToPrint}>
          Print Evaluasi
        </button>
        <button
          type="button"
          className="btn btn-flat-primary btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#modal-upload-evaluasi"
        >
          Upload Evaluasi
        </button>
      </td>
    </tr>
  );
};

EvaluasiBerjenjangTableRow.propTypes = {
  karyawanEvaluasi: PropTypes.shape({
    id: PropTypes.number.isRequired,
    npk: PropTypes.string.isRequired,
    nama: PropTypes.string.isRequired,
    pbp: PropTypes.string.isRequired,
    suratPSDMO: PropTypes.string.isRequired,
    posisiSekarang: PropTypes.shape({
      unitKerja: PropTypes.string.isRequired,
      jabatan: PropTypes.string.isRequired,
      jobGrade: PropTypes.string.isRequired,
      personalGrade: PropTypes.string.isRequired,
      atasan: PropTypes.string.isRequired,
      lokasi: PropTypes.string.isRequired
    })
  }).isRequired
};

export default EvaluasiBerjenjangTableRow;
