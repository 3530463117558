const poinEvaluasi = [
  {
    id: 1,
    pertanyaan: 'Perilaku PJS selama menjalani OJT (mengacu pada persyaratan jabatan)',
    jawaban: ''
  },
  {
    id: 2,
    pertanyaan: 'Pengetahuan / Keterampilan PJS selama menjalani OJT (mengacu pada persyaratan jabatan)',
    jawaban: ''
  },
  {
    id: 3,
    pertanyaan: 'Kinerja selama menjalani PJS (mengacu pada persyaratan Jabatan)',
    jawaban: ''
  },
  {
    id: 4,
    pertanyaan: 'Hubungan kerja dengan Sub Ordinat, Rekan Sejawat dan Atasan',
    jawaban: ''
  },
  {
    id: 4,
    pertanyaan: 'Kesimpulan Hasil Perilaku',
    jawaban: ''
  }
];

export default poinEvaluasi;
