import { Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { setTheme } from './utils/utils';
import Navbar from './Components/Navbar/Navbar';
import HomePage from './Pages/HomePage/HomePage';
import UsulanPage from './Pages/RMP/Usulan/UsulanPage/UsulanPage';
import UsulanBaruPage from './Pages/RMP/Usulan/UsulanBaruPage/UsulanBaruPage';
import UsulanDetailPage from './Pages/RMP/Usulan/UsulanDetailPage/UsulanDetailPage';
import SKKPage from './Pages/RMP/SKK/SKKPage/SKKPage';
import JobDescPage from './Pages/JobDesc/JobDescPage/JobDescPage';
import JobDescDetailPage from './Pages/JobDesc/JobDescDetailPage/JobDescDetailPage';
import UHCJobDescPage from './Pages/UHC/JobDescPage/JobDescPage';
import UHCPenjadwalanPage from './Pages/UHC/PenjadwalanPage/PenjadwalanPage';
import UHCPelaksanaanPage from './Pages/UHC/PelaksanaanPage/PelaksanaanPage';
import UHCHasilPage from './Pages/UHC/HasilPage/HasilPage';
import SettingPage from './Pages/SettingPage/SettingPage';
import Error404Page from './Pages/404/Error404Page';
import UsulanPromosiBertahapPage from './Pages/RMP/Usulan/UsulanPromosiBertahapPage/UsulanPromosiBertahapPage';
import IdeImprovementPage from './Pages/IdeImprovementPage/IdeImprovementPage';
import EvaluasiBerjenjangPage from './Pages/EvaluasiBerjenjangPage/EvaluasiBerjenjangPage';
import EvaluasiBerjenjangDetailPage from './Pages/EvaluasiBerjenjangDetailPage/EvaluasiBerjenjangDetailPage';

const App = () => {
  const isNightMode = useSelector((state) => state.user.isNightMode);
  useEffect(() => {
    setTheme(isNightMode);
  }, [isNightMode]);

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/rmp">
          <Route path="/rmp/usulan" element={<UsulanPage />} />
          <Route path="/rmp/usulan/buat" element={<UsulanBaruPage />} />
          <Route path="/rmp/usulan_bertahap/buat" element={<UsulanPromosiBertahapPage />} />
          <Route path="/rmp/usulan/detail/:usulanId" element={<UsulanDetailPage />} />

          <Route path="/rmp/skk" element={<SKKPage />} />
        </Route>

        <Route path="/jobdesc">
          <Route path="/jobdesc" element={<JobDescPage />} />
          <Route path="/jobdesc/detail" element={<JobDescDetailPage />} />
        </Route>

        <Route path="/uhc">
          <Route path="/uhc/jobdesc" element={<UHCJobDescPage />} />
          <Route path="/uhc/penjadwalan" element={<UHCPenjadwalanPage />} />
          <Route path="/uhc/pelaksanaan" element={<UHCPelaksanaanPage />} />
          <Route path="/uhc/hasil" element={<UHCHasilPage />} />
        </Route>

        <Route path="/ide_improvement" element={<IdeImprovementPage />} />
        <Route path="/evaluasi_berjenjang">
          <Route path="/evaluasi_berjenjang" element={<EvaluasiBerjenjangPage />} />
          <Route path="/evaluasi_berjenjang/detail" element={<EvaluasiBerjenjangDetailPage />} />
        </Route>

        <Route path="/setting" element={<SettingPage />} />

        <Route path="*" element={<Error404Page />} />
      </Routes>
    </>
  );
};

export default App;
