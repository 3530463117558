/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/control-has-associated-label */

import Select from 'react-select';

const UpdateHasilModal = () => {
  const rekomendasiOptions = [
    { value: 'Direkomendasikan', label: 'Direkomendasikan' },
    { value: 'Belum Direkomendasikan', label: 'Belum Direkomendasikan' }
  ];

  return (
    <div id="modal-update-hasil" className="modal fade" tabIndex="-1">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Update Hasil</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" />
          </div>

          <div className="modal-body">
            <h5>Surat Penilaian UHC</h5>
            <div className="row border-bottom">
              <div className="col-lg-4 mb-3">
                <input type="text" className="form-control" placeholder="No Surat" />
              </div>
              <div className="col-lg-4 mb-3">
                <input type="date" className="form-control" />
              </div>
            </div>

            <h5 className="mt-3">Hasil Penilaian UHC</h5>
            <div className="row border-bottom">
              <div className="col-5 mb-3">
                <Select
                  className="basic-single bg-transparent"
                  classNamePrefix="select"
                  options={rekomendasiOptions}
                  defaultValue={null}
                  placeholder="Pilih"
                />
              </div>
              <div className="col-lg-4 mb-3">
                <div className="input-group">
                  <input type="text" className="form-control" />
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </div>

            <h5 className="mt-3">Status Usulan</h5>
            <div>
              <div className="d-inline-flex align-items-center me-3">
                <input type="checkbox" id="dc_li_c" />
                <label className="ms-2" htmlFor="dc_li_c">Lanjut</label>
              </div>

              <div className="d-inline-flex align-items-center">
                <input type="checkbox" id="dc_li_u" />
                <label className="ms-2" htmlFor="dc_li_u">Gagal</label>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-link" data-bs-dismiss="modal">Tutup</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateHasilModal;
