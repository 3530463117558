import SimpleList from '../../Components/SimpleList/SimpleList';
import FormPenilaianPejabatSementara from './FormPenilaianPejabatSementara';
import KaryawanCard from './KaryawanCard';
import poinEvaluasi from '../../dummy_data/poinEvaluasi';
import FormEvaluasi from './FormEvaluasi';
import RekomendasiCard from './RekomendasiCard';

const EvaluasiBerjenjangDetailPage = () => (
  <div className="page-content" data-testid="home-page">
    <div className="content-wrapper">
      <div className="content-inner">
        <div className="page-header">
          <div className="page-header-content container d-lg-flex">
            <div className="d-flex">
              <h4 className="page-title mb-0">
                Penilaian Pejabat Sementara
              </h4>
            </div>
          </div>
        </div>
        <div className="content container pt-0">
          <FormPenilaianPejabatSementara />
          <KaryawanCard />

          <SimpleList
            data={poinEvaluasi}
            renderItem={(item) => (
              <FormEvaluasi
                key={item.id}
                poinEvaluasi={item}
              />
            )}
          />
          <RekomendasiCard />
          <div className="d-flex flex-row-reverse">
            <button type="button" className="btn btn-light">
              Kembali
            </button>
            <button type="submit" className="btn btn-primary me-2">
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default EvaluasiBerjenjangDetailPage;
