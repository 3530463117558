/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

import Select from 'react-select';
import PropTypes from 'prop-types';
import { tipeUsulanRMP } from '../../../../utils/enum';

const UsulanFilter = ({ tabType }) => {
  const { ROTASI, MUTASI, PROMOSI } = tipeUsulanRMP;

  const tipeUsulanOptions = [
    { value: ROTASI, label: ROTASI, color: '#5C6BBF' },
    { value: MUTASI, label: MUTASI, color: '#26A69A' },
    { value: PROMOSI, label: PROMOSI, color: '#F25C85' }
  ];

  const unitKerjaOptions = [
    { value: '1', label: 'Departemen Perencanaan, Penerimaan & Pergudangan' },
    { value: '2', label: 'Kompartemen Pemeliharaan' },
    { value: '3', label: 'Departemen Akuntansi' },
    { value: '4', label: 'Departemen Anggaran' },
    { value: '5', label: 'Departemen Pengembangan Korporat' },
    { value: '6', label: 'Direktorat Keuangan & Umum' },
    { value: '7', label: 'Departemen Operasi Pabrik 4' },
    { value: '8', label: 'Direktorat Operasi & Produksi' },
    { value: '9', label: 'Departemen Inovasi & Pengembangan Manajemen' },
    { value: '10', label: 'Departemen Keamanan' },
    { value: '11', label: 'Departemen Administrasi Korporasi' }
  ];

  const lokasiOptions = [
    { value: '1', label: 'Bontang' },
    { value: '2', label: 'Makassar' },
    { value: '3', label: 'Surabaya' },
    { value: '4', label: 'Jakarta' },
    { value: '5', label: 'Bintuni' }
  ];

  const tipePromosiOptions = [
    { value: '12', label: '1~2' },
    { value: '37', label: '3~7' }
  ];

  const statusUHCOptions = [
    { value: 'Proses', label: 'Proses' },
    { value: 'Lanjut', label: 'Lanjut' },
    { value: 'Gagal', label: 'Gagal' }
  ];

  const tipeUsulanOptionsStyles = {
    option: (provided, { data }) => ({
      ...provided,
      width: 'fit-content',
      backgroundColor: data.color,
      paddingLeft: '0.4375rem',
      paddingRight: '0.4375rem',
      paddingTop: '0.3125rem',
      paddingBottom: '0.3125rem',
      color: '#fff',
      fontWeight: '600',
      borderRadius: '0.25rem',
      fontSize: '0.75rem',
      margin: '0.5rem'
    }),
    singleValue: (provided, { data }) => ({
      ...provided,
      width: 'fit-content',
      backgroundColor: data.color,
      paddingLeft: '0.4375rem',
      paddingRight: '0.4375rem',
      paddingTop: '0.3125rem',
      paddingBottom: '0.3125rem',
      color: '#fff',
      fontWeight: '600',
      borderRadius: '0.25rem',
      fontSize: '0.75rem'
    })
  };

  return (
    <div className="card">
      <div className="card-header bg-primary bg-opacity-10 text-primary fw-bold">
        <div className="mb-0">Filter Usulan</div>
      </div>

      <div className="card-body">
        <div className="row">
          <div className="col-lg-4 mb-3">
            <label className="form-label">No Usulan</label>
            <input type="text" className="form-control" />
          </div>
          <div className="col-lg-4 mb-3">
            <label className="form-label">NPK</label>
            <input type="text" className="form-control" />
          </div>
          <div className="col-lg-4 mb-3">
            <label className="form-label">Nama Karyawan</label>
            <input type="text" className="form-control" />
          </div>
          <div className="col-lg-3 mb-3">
            <label className="form-label">Unit Kerja Sekarang</label>
            <Select
              className="basic-single bg-transparent"
              classNamePrefix="select"
              options={unitKerjaOptions}
              defaultValue={null}
              placeholder="Pilih unit kerja"
            />
          </div>
          <div className="col-lg-3 mb-3">
            <label className="form-label">Lokasi Sekarang</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              options={lokasiOptions}
              defaultValue={null}
              placeholder="Pilih lokasi"
            />
          </div>
          <div className="col-lg-3 mb-3">
            <label className="form-label">Unit Kerja Usulan</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              options={unitKerjaOptions}
              defaultValue={null}
              placeholder="Pilih unit kerja"
            />
          </div>
          <div className="col-lg-3 mb-3">
            <label className="form-label">Lokasi Usulan</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              options={lokasiOptions}
              defaultValue={null}
              placeholder="Pilih lokasi"
            />
          </div>
          {tabType === 'all' && (
            <div className="col-lg-4 mb-3">
              <label className="form-label">Tipe Usulan</label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                options={tipeUsulanOptions}
                defaultValue={null}
                placeholder="Pilih tipe usulan"
                styles={tipeUsulanOptionsStyles}
              />
            </div>
          )}
          {tabType === 'promosi' && (
            <div className="col-lg-4 mb-3">
              <label className="form-label">Grade</label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                options={tipePromosiOptions}
                defaultValue={null}
                placeholder="Pilih grade"
              />
            </div>
          )}
          {tabType === 'uhc' && (
          <div className="col-lg-4 mb-3">
            <label className="form-label">Status UHC</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              options={statusUHCOptions}
              defaultValue={null}
              placeholder="Pilih status UHC"
            />
          </div>
          )}
        </div>
        <div className="d-flex flex-row-reverse">
          <button type="button" className="btn btn-primary my-1 me-2">Filter</button>
          <button type="button" className="btn btn-light my-1 me-2">Reset</button>
        </div>
      </div>
    </div>
  );
};

UsulanFilter.defaultProps = {
  tabType: ''
};

UsulanFilter.propTypes = {
  tabType: PropTypes.string
};

export default UsulanFilter;
