/* eslint-disable jsx-a11y/anchor-is-valid */
const Breadcrumb = () => (
  <div className="page-header">
    <div className="page-header-content container d-lg-flex">
      <div className="page-title">
        <h5 className="mb-0">
          Breadcrumb -
          {' '}
          <span className="fw-normal">Bottom Condensed</span>
        </h5>

        <div className="breadcrumb mt-1">
          <a href="index.html" className="breadcrumb-item">Home</a>
          <a href="#" className="breadcrumb-item">Components</a>
          <span className="breadcrumb-item active">Breadcrumbs</span>
        </div>
      </div>
    </div>
  </div>
);

export default Breadcrumb;
