/* eslint-disable jsx-a11y/control-has-associated-label */

import { Printer } from 'phosphor-react';

const DokumenPelaksanaanModal = () => (
  <div id="modal-dokumen-pelaksanaan" className="modal fade" tabIndex="-1">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Dokumen Pelaksanaan</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" />
        </div>

        <div className="modal-body">
          <h5>Undangan</h5>
          <div className="row border-bottom">
            <div className="col-auto mb-3">
              <button type="button" className="btn btn-flat-secondary">
                <Printer className="me-2" size={18} />
                Asesi
              </button>
            </div>
            <div className="col-auto mb-3">
              <button type="button" className="btn btn-flat-secondary">
                <Printer className="me-2" size={18} />
                Asesor
              </button>
            </div>
          </div>
          <h5 className="mt-3">Absensi</h5>
          <div className="row border-bottom">
            <div className="col-auto mb-3">
              <button type="button" className="btn btn-flat-secondary">
                <Printer className="me-2" size={18} />
                Print PDF
              </button>
            </div>
          </div>
          <h5 className="mt-3">Form Penilaian</h5>
          <div className="row">
            <div className="col-auto mb-3">
              <button type="button" className="btn btn-flat-secondary">
                <Printer className="me-2" size={18} />
                Print PDF
              </button>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button type="button" className="btn btn-link" data-bs-dismiss="modal">Tutup</button>
        </div>
      </div>
    </div>
  </div>
);

export default DokumenPelaksanaanModal;
