/* eslint-disable jsx-a11y/anchor-is-valid */
import { CaretLeft, CaretRight } from 'phosphor-react';

const TablePagination = () => (
  <div className="pagination d-flex align-items-center">
    <span className="text-muted me-auto">Showing 3 of 3 items</span>

    <div className="page-item disabled">
      <a href="#" className="page-link rounded-pill px-0">
        <CaretLeft />
      </a>
    </div>
    <div className="px-3">
      1 of 1
    </div>
    <div className="page-item disabled">
      <a href="#" className="page-link rounded-pill px-0">
        <CaretRight />
      </a>
    </div>
  </div>
);

export default TablePagination;
