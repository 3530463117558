const karyawanEvaluasi = [
  {
    id: 1,
    npk: '0603619',
    nama: 'Puguh Prasetyo',
    pbp: '2042-04-11',
    suratPSDMO: '1',
    posisiSekarang: {
      unitKerja: 'Departemen Inovasi & Pengembangan Manajemen',
      jabatan: 'Staf Pratama II Pengelolaan Prosedur',
      jobGrade: '4B',
      personalGrade: '4B'
    }
  },
  {
    id: 2,
    npk: '1404331',
    nama: 'Firsta Hardiyanto',
    pbp: '2046-12-01',
    suratPSDMO: '1',
    posisiSekarang: {
      unitKerja: 'Direktorat Operasi & Produksi',
      jabatan: 'Staf Muda I Direktur Operasi & Produksi Penugasan Sebagai Staf Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      jobGrade: '3A',
      personalGrade: '3A'
    }
  },
  {
    id: 3,
    npk: '8803005',
    nama: 'Damarsasi',
    pbp: '2023-07-09',
    suratPSDMO: '1',
    posisiSekarang: {
      unitKerja: 'Departemen Administrasi Korporasi',
      jabatan: 'AVP Kearsipan & Administrasi',
      jobGrade: '3A',
      personalGrade: '3A'
    }
  }
];

export default karyawanEvaluasi;
