const skk = [
  {
    id: 1,
    npk: '0603619',
    nama: 'Puguh Prasetyo',
    usulan: 'Rotasi',
    tanggalDibuat: '2022-08-22 09:44:20',
    tanggalUsulan: '2022-08-11',
    noUsulan: 'bypass',
    usulanDari: 'VP Keamanan',
    mdg: '2.33 / 2 tahun 3 bulan 29 hari',
    pbp: '2042-04-11',
    statusSKK: 'Administrasi',
    tmt: '2022-10-11',
    positionId: '1',
    posisiSekarang: {
      unitKerja: 'Departemen Inovasi & Pengembangan Manajemen',
      jabatan: 'Staf Pratama II Pengelolaan Prosedur',
      jobGrade: '4B',
      personalGrade: '4B',
      atasan: 'AVP Pengelolaan Prosedur',
      lokasi: 'Bontang'
    },
    posisiUsulan: {
      unitKerja: 'Departemen Keamanan',
      jabatan: 'Staf Pratama II Administrasi & Perijinan',
      jobGrade: '4B',
      personalGrade: '4B',
      atasan: 'AVP Administrasi & Perijinan',
      lokasi: 'Bontang'
    }
  },
  {
    id: 2,
    npk: '1404331',
    nama: 'Firsta Hardiyanto',
    usulan: 'Mutasi',
    tanggalDibuat: '2022-06-29 07:27:24',
    tanggalUsulan: '2022-06-29',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    mdg: '0.70 / 8 bulan 14 hari',
    pbp: '2046-12-01',
    statusSKK: 'Administrasi',
    tmt: '2022-10-11',
    positionId: '1',
    posisiSekarang: {
      unitKerja: 'Direktorat Operasi & Produksi',
      jabatan: 'Staf Muda I Direktur Operasi & Produksi Penugasan Sebagai Staf Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'Koordinator Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      lokasi: 'Bintuni'
    },
    posisiUsulan: {
      unitKerja: 'Direktorat Operasi & Produksi',
      jabatan: 'Staf Muda I Direktur Operasi & Produksi Penugasan Sebagai Staf Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'Koordinator Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      lokasi: 'Jakarta'
    }
  },
  {
    id: 3,
    npk: '8803005',
    nama: 'Damarsasi',
    usulan: 'Promosi',
    tanggalDibuat: '2022-08-18 08:02:38',
    tanggalUsulan: '2022-08-18',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    mdg: '6.16 / 6 tahun 1 bulan 28 hari',
    pbp: '2023-07-09',
    statusSKK: 'Administrasi',
    tmt: '2022-10-11',
    positionId: '1',
    posisiSekarang: {
      unitKerja: 'Departemen Administrasi Korporasi',
      jabatan: 'AVP Kearsipan & Administrasi',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'VP Administrasi Korporasi',
      lokasi: 'Bontang'
    },
    posisiUsulan: {
      unitKerja: 'Direktorat Keuangan & Umum',
      jabatan: 'Direktur PT Kaltim Nusa Etika',
      jobGrade: '2A',
      personalGrade: '2B',
      atasan: 'Direktur Keuangan & Umum',
      lokasi: 'Bontang'
    }
  },
  {
    id: 4,
    npk: '0603619',
    nama: 'Puguh Prasetyo',
    usulan: 'Rotasi',
    tanggalDibuat: '2022-08-22 09:44:20',
    tanggalUsulan: '2022-08-11',
    noUsulan: 'bypass',
    usulanDari: 'VP Keamanan',
    mdg: '2.33 / 2 tahun 3 bulan 29 hari',
    pbp: '2042-04-11',
    statusSKK: 'Print',
    tmt: '2022-10-11',
    positionId: '1',
    posisiSekarang: {
      unitKerja: 'Departemen Inovasi & Pengembangan Manajemen',
      jabatan: 'Staf Pratama II Pengelolaan Prosedur',
      jobGrade: '4B',
      personalGrade: '4B',
      atasan: 'AVP Pengelolaan Prosedur',
      lokasi: 'Bontang'
    },
    posisiUsulan: {
      unitKerja: 'Departemen Keamanan',
      jabatan: 'Staf Pratama II Administrasi & Perijinan',
      jobGrade: '4B',
      personalGrade: '4B',
      atasan: 'AVP Administrasi & Perijinan',
      lokasi: 'Bontang'
    }
  },
  {
    id: 5,
    npk: '1404331',
    nama: 'Firsta Hardiyanto',
    usulan: 'Mutasi',
    tanggalDibuat: '2022-06-29 07:27:24',
    tanggalUsulan: '2022-06-29',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    mdg: '0.70 / 8 bulan 14 hari',
    pbp: '2046-12-01',
    statusSKK: 'Print',
    tmt: '2022-10-11',
    positionId: '1',
    posisiSekarang: {
      unitKerja: 'Direktorat Operasi & Produksi',
      jabatan: 'Staf Muda I Direktur Operasi & Produksi Penugasan Sebagai Staf Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'Koordinator Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      lokasi: 'Bintuni'
    },
    posisiUsulan: {
      unitKerja: 'Direktorat Operasi & Produksi',
      jabatan: 'Staf Muda I Direktur Operasi & Produksi Penugasan Sebagai Staf Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'Koordinator Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      lokasi: 'Jakarta'
    }
  },
  {
    id: 6,
    npk: '8803005',
    nama: 'Damarsasi',
    usulan: 'Promosi',
    tanggalDibuat: '2022-08-18 08:02:38',
    tanggalUsulan: '2022-08-18',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    mdg: '6.16 / 6 tahun 1 bulan 28 hari',
    pbp: '2023-07-09',
    statusSKK: 'Print',
    tmt: '2022-10-11',
    positionId: '1',
    posisiSekarang: {
      unitKerja: 'Departemen Administrasi Korporasi',
      jabatan: 'AVP Kearsipan & Administrasi',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'VP Administrasi Korporasi',
      lokasi: 'Bontang'
    },
    posisiUsulan: {
      unitKerja: 'Direktorat Keuangan & Umum',
      jabatan: 'Direktur PT Kaltim Nusa Etika',
      jobGrade: '2A',
      personalGrade: '2B',
      atasan: 'Direktur Keuangan & Umum',
      lokasi: 'Bontang'
    }
  },
  {
    id: 7,
    npk: '0603619',
    nama: 'Puguh Prasetyo',
    usulan: 'Rotasi',
    tanggalDibuat: '2022-08-22 09:44:20',
    tanggalUsulan: '2022-08-11',
    noUsulan: 'bypass',
    usulanDari: 'VP Keamanan',
    mdg: '2.33 / 2 tahun 3 bulan 29 hari',
    pbp: '2042-04-11',
    statusSKK: 'Penandatanganan',
    tmt: '2022-10-11',
    positionId: '1',
    posisiSekarang: {
      unitKerja: 'Departemen Inovasi & Pengembangan Manajemen',
      jabatan: 'Staf Pratama II Pengelolaan Prosedur',
      jobGrade: '4B',
      personalGrade: '4B',
      atasan: 'AVP Pengelolaan Prosedur',
      lokasi: 'Bontang'
    },
    posisiUsulan: {
      unitKerja: 'Departemen Keamanan',
      jabatan: 'Staf Pratama II Administrasi & Perijinan',
      jobGrade: '4B',
      personalGrade: '4B',
      atasan: 'AVP Administrasi & Perijinan',
      lokasi: 'Bontang'
    }
  },
  {
    id: 8,
    npk: '1404331',
    nama: 'Firsta Hardiyanto',
    usulan: 'Mutasi',
    tanggalDibuat: '2022-06-29 07:27:24',
    tanggalUsulan: '2022-06-29',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    mdg: '0.70 / 8 bulan 14 hari',
    pbp: '2046-12-01',
    statusSKK: 'Penandatanganan',
    tmt: '2022-10-11',
    positionId: '1',
    posisiSekarang: {
      unitKerja: 'Direktorat Operasi & Produksi',
      jabatan: 'Staf Muda I Direktur Operasi & Produksi Penugasan Sebagai Staf Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'Koordinator Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      lokasi: 'Bintuni'
    },
    posisiUsulan: {
      unitKerja: 'Direktorat Operasi & Produksi',
      jabatan: 'Staf Muda I Direktur Operasi & Produksi Penugasan Sebagai Staf Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'Koordinator Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      lokasi: 'Jakarta'
    }
  },
  {
    id: 9,
    npk: '8803005',
    nama: 'Damarsasi',
    usulan: 'Promosi',
    tanggalDibuat: '2022-08-18 08:02:38',
    tanggalUsulan: '2022-08-18',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    mdg: '6.16 / 6 tahun 1 bulan 28 hari',
    pbp: '2023-07-09',
    statusSKK: 'Penandatanganan',
    tmt: '2022-10-11',
    positionId: '1',
    posisiSekarang: {
      unitKerja: 'Departemen Administrasi Korporasi',
      jabatan: 'AVP Kearsipan & Administrasi',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'VP Administrasi Korporasi',
      lokasi: 'Bontang'
    },
    posisiUsulan: {
      unitKerja: 'Direktorat Keuangan & Umum',
      jabatan: 'Direktur PT Kaltim Nusa Etika',
      jobGrade: '2A',
      personalGrade: '2B',
      atasan: 'Direktur Keuangan & Umum',
      lokasi: 'Bontang'
    }
  }
];

export default skk;
