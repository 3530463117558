import CatatanRMPModal from '../CatatanRMPModal';
import UHCFilter from '../UHCFilter';
import DetailPelaksanaanModal from './DetailPelaksanaanModal';
import DetailPelaksanaanModal2 from './DetailPelaksanaanModal2';
import DokumenPelaksanaanModal from './DokumenPelaksanaanModal';
import PelaksanaanTable from './PelaksanaanTable';
import PilihWitnessModal from './PilihWitnessModal';

const PelaksanaanPage = () => (
  <div className="page-content">
    <div className="content-wrapper">
      <div className="content-inner">
        <div className="page-header">
          <div className="page-header-content container d-lg-flex">
            <div className="d-flex">
              <h4 className="page-title mb-0">
                Home -
                {' '}
                <span className="fw-normal">Dashboard</span>
              </h4>
            </div>
          </div>
        </div>
        <div className="content container pt-0">
          <UHCFilter />
          <PelaksanaanTable />
        </div>
      </div>
    </div>
    <CatatanRMPModal />
    <DetailPelaksanaanModal />
    <DetailPelaksanaanModal2 />
    <DokumenPelaksanaanModal />
    <PilihWitnessModal />
  </div>
);

export default PelaksanaanPage;
