const usulanRMP = [
  {
    id: 1,
    npk: '0603619',
    nama: 'Puguh Prasetyo',
    tipeUsulan: 'Rotasi',
    tanggalDibuat: '2022-08-22 09:44:20',
    tanggalUsulan: '2022-08-11',
    noUsulan: 'bypass',
    usulanDari: 'VP Keamanan',
    mdg: '2.33 / 2 tahun 3 bulan 29 hari',
    pbp: '2042-04-11',
    statusUsulan: 'Administrasi',
    persetujuanBoDPKT: 'Belum',
    persetujuanPI: 'Belum',
    catatan: 'catatan',
    statusSKK: 'Proses',
    suratKetetapanPegawai: '',
    posisiSekarang: {
      unitKerja: 'Departemen Inovasi & Pengembangan Manajemen',
      jabatan: 'Staf Pratama II Pengelolaan Prosedur',
      jobGrade: '4B',
      personalGrade: '4B',
      atasan: 'AVP Pengelolaan Prosedur',
      lokasi: 'Bontang'
    },
    posisiUsulan: {
      unitKerja: 'Departemen Keamanan',
      jabatan: 'Staf Pratama II Administrasi & Perijinan',
      jobGrade: '4B',
      personalGrade: '4B',
      atasan: 'AVP Administrasi & Perijinan',
      lokasi: 'Bontang'
    }
  },
  {
    id: 2,
    npk: '1404331',
    nama: 'Firsta Hardiyanto',
    tipeUsulan: 'Mutasi',
    tanggalDibuat: '2022-06-29 07:27:24',
    tanggalUsulan: '2022-06-29',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    mdg: '0.70 / 8 bulan 14 hari',
    pbp: '2046-12-01',
    statusUsulan: 'Persetujuan BoD PKT',
    persetujuanBoDPKT: 'Wawancara',
    persetujuanPI: 'Belum',
    catatan: 'catatan',
    statusSKK: 'Proses',
    suratKetetapanPegawai: '',
    posisiSekarang: {
      unitKerja: 'Direktorat Operasi & Produksi',
      jabatan: 'Staf Muda I Direktur Operasi & Produksi Penugasan Sebagai Staf Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'Koordinator Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      lokasi: 'Bintuni'
    },
    posisiUsulan: {
      unitKerja: 'Direktorat Operasi & Produksi',
      jabatan: 'Staf Muda I Direktur Operasi & Produksi Penugasan Sebagai Staf Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'Koordinator Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      lokasi: 'Jakarta'
    }
  },
  {
    id: 3,
    npk: '0603619',
    nama: 'Puguh Prasetyo',
    tipeUsulan: 'Rotasi',
    tanggalDibuat: '2022-08-22 09:44:20',
    tanggalUsulan: '2022-08-11',
    noUsulan: 'bypass',
    usulanDari: 'VP Keamanan',
    mdg: '2.33 / 2 tahun 3 bulan 29 hari',
    pbp: '2042-04-11',
    statusUsulan: 'Persetujuan PI',
    persetujuanBoDPKT: 'Selesai',
    persetujuanPI: 'Proses',
    catatan: 'catatan',
    statusSKK: 'Proses',
    suratKetetapanPegawai: 'Dalam Proses',
    posisiSekarang: {
      unitKerja: 'Departemen Inovasi & Pengembangan Manajemen',
      jabatan: 'Staf Pratama II Pengelolaan Prosedur',
      jobGrade: '4B',
      personalGrade: '4B',
      atasan: 'AVP Pengelolaan Prosedur',
      lokasi: 'Bontang'
    },
    posisiUsulan: {
      unitKerja: 'Departemen Keamanan',
      jabatan: 'Staf Pratama II Administrasi & Perijinan',
      jobGrade: '4B',
      personalGrade: '4B',
      atasan: 'AVP Administrasi & Perijinan',
      lokasi: 'Bontang'
    }
  },
  {
    id: 4,
    npk: '1404331',
    nama: 'Firsta Hardiyanto',
    tipeUsulan: 'Mutasi',
    tanggalDibuat: '2022-06-29 07:27:24',
    tanggalUsulan: '2022-06-29',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    mdg: '0.70 / 8 bulan 14 hari',
    pbp: '2046-12-01',
    statusUsulan: 'UHC',
    persetujuanBoDPKT: 'Tidak Diperlukan',
    persetujuanPI: 'Selesai',
    catatan: 'catatan',
    statusSKK: 'Proses',
    suratKetetapanPegawai: 'Dalam Proses',
    posisiSekarang: {
      unitKerja: 'Direktorat Operasi & Produksi',
      jabatan: 'Staf Muda I Direktur Operasi & Produksi Penugasan Sebagai Staf Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'Koordinator Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      lokasi: 'Bintuni'
    },
    posisiUsulan: {
      unitKerja: 'Direktorat Operasi & Produksi',
      jabatan: 'Staf Muda I Direktur Operasi & Produksi Penugasan Sebagai Staf Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'Koordinator Bidang Administrasi Tim Persiapan dan Pelelangan Proyek Petrokimia Bintuni',
      lokasi: 'Jakarta'
    }
  },
  {
    id: 5,
    npk: '8803005',
    nama: 'Damarsasi',
    tipeUsulan: 'Promosi',
    tanggalDibuat: '2022-08-18 08:02:38',
    tanggalUsulan: '2022-08-18',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    mdg: '6.16 / 6 tahun 1 bulan 28 hari',
    pbp: '2023-07-09',
    tipePromosi: '37',
    statusUsulan: 'Administrasi',
    asesmenPotensi: 'Belum Disarankan',
    asesmenPotensiValue: '60%',
    statusUHC: '',
    tanggalPermintaanUHC: '',
    tanggalSelesaiUHC: '',
    hasilUHC: '',
    statusUsulanDanHasil: '',
    catatan: '',
    statusSKK: 'Belum',
    suratKetetapanPegawai: '',
    posisiSekarang: {
      unitKerja: 'Departemen Administrasi Korporasi',
      jabatan: 'AVP Kearsipan & Administrasi',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'VP Administrasi Korporasi',
      lokasi: 'Bontang'
    },
    posisiUsulan: {
      unitKerja: 'Direktorat Keuangan & Umum',
      jabatan: 'Direktur PT Kaltim Nusa Etika',
      jobGrade: '2A',
      personalGrade: '2B',
      atasan: 'Direktur Keuangan & Umum',
      lokasi: 'Bontang'
    }
  },
  {
    id: 6,
    npk: '8803005',
    nama: 'Damarsasi',
    tipeUsulan: 'Promosi',
    tanggalDibuat: '2022-08-18 08:02:38',
    tanggalUsulan: '2022-08-18',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    mdg: '6.16 / 6 tahun 1 bulan 28 hari',
    pbp: '2023-07-09',
    tipePromosi: '37',
    statusUsulan: 'SKK',
    asesmenPotensi: 'Disarankan',
    asesmenPotensiValue: '90%',
    statusUHC: 'Proses',
    tanggalPermintaanUHC: '2020-09-01',
    tanggalSelesaiUHC: '',
    hasilUHC: '',
    statusUsulanDanHasil: '',
    catatan: '',
    statusSKK: 'Proses',
    suratKetetapanPegawai: '',
    posisiSekarang: {
      unitKerja: 'Departemen Administrasi Korporasi',
      jabatan: 'AVP Kearsipan & Administrasi',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'VP Administrasi Korporasi',
      lokasi: 'Bontang'
    },
    posisiUsulan: {
      unitKerja: 'Direktorat Keuangan & Umum',
      jabatan: 'Direktur PT Kaltim Nusa Etika',
      jobGrade: '2A',
      personalGrade: '2B',
      atasan: 'Direktur Keuangan & Umum',
      lokasi: 'Bontang'
    }
  },
  {
    id: 7,
    npk: '8803005',
    nama: 'Damarsasi',
    tipeUsulan: 'Promosi',
    tanggalDibuat: '2022-08-18 08:02:38',
    tanggalUsulan: '2022-08-18',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    mdg: '6.16 / 6 tahun 1 bulan 28 hari',
    pbp: '2023-07-09',
    tipePromosi: '12',
    statusUsulan: 'Administrasi',
    asesmenPotensi: 'Belum Disarankan',
    asesmenPotensiValue: '60%',
    ideImprovement: 'Belum Diisi',
    statusUHC: '',
    tanggalPermintaanUHC: '',
    tanggalSelesaiUHC: '',
    hasilUHC: '',
    statusUsulanDanHasil: '',
    persetujuanBoDPKT: 'Belum',
    persetujuanPI: 'Belum',
    catatan: '',
    statusSKK: 'Belum',
    suratKetetapanPegawai: '',
    posisiSekarang: {
      unitKerja: 'Departemen Administrasi Korporasi',
      jabatan: 'AVP Kearsipan & Administrasi',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'VP Administrasi Korporasi',
      lokasi: 'Bontang'
    },
    posisiUsulan: {
      unitKerja: 'Direktorat Keuangan & Umum',
      jabatan: 'Direktur PT Kaltim Nusa Etika',
      jobGrade: '2A',
      personalGrade: '2B',
      atasan: 'Direktur Keuangan & Umum',
      lokasi: 'Bontang'
    }
  },
  {
    id: 8,
    npk: '8803005',
    nama: 'Damarsasi',
    tipeUsulan: 'Promosi',
    tanggalDibuat: '2022-08-18 08:02:38',
    tanggalUsulan: '2022-08-18',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    mdg: '6.16 / 6 tahun 1 bulan 28 hari',
    pbp: '2023-07-09',
    tipePromosi: '12',
    statusUsulan: 'Administrasi',
    asesmenPotensi: 'Belum Disarankan',
    asesmenPotensiValue: '60%',
    ideImprovement: 'Sudah Diisi',
    statusUHC: 'Proses',
    tanggalPermintaanUHC: '2022-09-01',
    tanggalSelesaiUHC: '',
    hasilUHC: '',
    statusUsulanDanHasil: '',
    persetujuanBoDPKT: 'Belum',
    persetujuanPI: 'Belum',
    catatan: '',
    statusSKK: 'Belum',
    suratKetetapanPegawai: '',
    posisiSekarang: {
      unitKerja: 'Departemen Administrasi Korporasi',
      jabatan: 'AVP Kearsipan & Administrasi',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'VP Administrasi Korporasi',
      lokasi: 'Bontang'
    },
    posisiUsulan: {
      unitKerja: 'Direktorat Keuangan & Umum',
      jabatan: 'Direktur PT Kaltim Nusa Etika',
      jobGrade: '2A',
      personalGrade: '2B',
      atasan: 'Direktur Keuangan & Umum',
      lokasi: 'Bontang'
    }
  },
  {
    id: 9,
    npk: '8803005',
    nama: 'Damarsasi',
    tipeUsulan: 'Promosi',
    tanggalDibuat: '2022-08-18 08:02:38',
    tanggalUsulan: '2022-08-18',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    mdg: '6.16 / 6 tahun 1 bulan 28 hari',
    pbp: '2023-07-09',
    tipePromosi: '12',
    statusUsulan: 'Administrasi',
    asesmenPotensi: 'Belum Disarankan',
    asesmenPotensiValue: '60%',
    ideImprovement: 'Sudah Diisi',
    statusUHC: 'Selesai',
    tanggalPermintaanUHC: '2022-09-01',
    tanggalSelesaiUHC: '2022-09-10',
    hasilUHC: 'Direkomendasikan',
    hasilUHCValue: '90%',
    statusUsulanDanHasil: 'Lanjut',
    persetujuanBoDPKT: 'Selesai',
    persetujuanPI: 'Selesai',
    catatan: '',
    statusSKK: 'Proses',
    suratKetetapanPegawai: 'Dalam Proses',
    posisiSekarang: {
      unitKerja: 'Departemen Administrasi Korporasi',
      jabatan: 'AVP Kearsipan & Administrasi',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'VP Administrasi Korporasi',
      lokasi: 'Bontang'
    },
    posisiUsulan: {
      unitKerja: 'Direktorat Keuangan & Umum',
      jabatan: 'Direktur PT Kaltim Nusa Etika',
      jobGrade: '2A',
      personalGrade: '2B',
      atasan: 'Direktur Keuangan & Umum',
      lokasi: 'Bontang'
    }
  },
  {
    id: 10,
    npk: '8803005',
    nama: 'Damarsasi',
    tipeUsulan: 'Promosi Bertahap',
    tanggalDibuat: '2022-08-18 08:02:38',
    tanggalUsulan: '2022-08-18',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    mdg: '6.16 / 6 tahun 1 bulan 28 hari',
    pbp: '2023-07-09',
    statusUsulan: 'Proses Administrasi',
    noSuratKeluar: '123',
    formEvaluasi: 'PJS',
    linkEvaluasi: '',
    hasilEvaluasi: '',
    suratKetetapanPegawai: '',
    posisiSekarang: {
      unitKerja: 'Departemen Administrasi Korporasi',
      jabatan: 'AVP Kearsipan & Administrasi',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'VP Administrasi Korporasi',
      lokasi: 'Bontang'
    },
    posisiUsulan: {
      unitKerja: 'Direktorat Keuangan & Umum',
      jabatan: 'Direktur PT Kaltim Nusa Etika',
      jobGrade: '2A',
      personalGrade: '2B',
      atasan: 'Direktur Keuangan & Umum',
      lokasi: 'Bontang'
    }
  },
  {
    id: 11,
    npk: '8803005',
    nama: 'Damarsasi',
    tipeUsulan: 'Promosi Bertahap',
    tanggalDibuat: '2022-08-18 08:02:38',
    tanggalUsulan: '2022-08-18',
    noUsulan: 'bypass',
    usulanDari: 'bypass',
    mdg: '6.16 / 6 tahun 1 bulan 28 hari',
    pbp: '2023-07-09',
    statusUsulan: 'Menunggu SKK',
    noSuratKeluar: '123',
    formEvaluasi: 'PJS',
    linkEvaluasi: 'google.com',
    hasilEvaluasi: 'Direkomendasikan',
    suratKetetapanPegawai: '',
    posisiSekarang: {
      unitKerja: 'Departemen Administrasi Korporasi',
      jabatan: 'AVP Kearsipan & Administrasi',
      jobGrade: '3A',
      personalGrade: '3A',
      atasan: 'VP Administrasi Korporasi',
      lokasi: 'Bontang'
    },
    posisiUsulan: {
      unitKerja: 'Direktorat Keuangan & Umum',
      jabatan: 'Direktur PT Kaltim Nusa Etika',
      jobGrade: '2A',
      personalGrade: '2B',
      atasan: 'Direktur Keuangan & Umum',
      lokasi: 'Bontang'
    }
  }
];

export default usulanRMP;
