/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/control-has-associated-label */

import Select from 'react-select';

const UpdateJabatanModal = () => {
  const statusOptions = [
    { value: 'Tersedia', label: 'Tersedia' },
    { value: 'Belum Tersedia', label: 'Belum Tersedia' }
  ];

  const formatOptions = [
    { value: 'Baru', label: 'Baru' },
    { value: 'Lama', label: 'Lama' }
  ];

  return (
    <div id="modal-update-jabatan" className="modal fade" tabIndex="-1">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Update Jabatan</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" />
          </div>

          <div className="modal-body">
            <div className="row">
              <div className="col-5 mb-3">
                <label className="form-label">Status</label>
                <Select
                  className="basic-single bg-transparent"
                  classNamePrefix="select"
                  options={statusOptions}
                  defaultValue={null}
                  placeholder="Pilih"
                />
              </div>
              <div className="col-5 mb-3">
                <label className="form-label">Format</label>
                <Select
                  className="basic-single bg-transparent"
                  classNamePrefix="select"
                  options={formatOptions}
                  defaultValue={null}
                  placeholder="Pilih"
                />
              </div>
              <div className="col-lg-4 mb-3">
                <label className="form-label">Tahun</label>
                <input type="text" className="form-control" />
              </div>
              <div className="col-lg-4 mb-3">
                <label className="form-label">No Surat</label>
                <input type="text" className="form-control" />
              </div>
              <div className="col-12 mb-3">
                <label className="form-label">Link Surat</label>
                <input type="text" className="form-control" />
              </div>
            </div>

          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-link" data-bs-dismiss="modal">Tutup</button>
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Simpan</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateJabatanModal;
