/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import Select from 'react-select';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import withReactContent from 'sweetalert2-react-content';

const PersyaratanPromosiBertahapForm = ({ persyaratan }) => {
  const MySwal = withReactContent(Swal);

  const batalkan = () => {
    MySwal.fire({
      icon: 'warning',
      title: 'Apakah anda yakin?',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonText: 'Ya',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-flat-primary',
        cancelButton: 'btn btn-light '
      }
    });
  };

  const ajukanEvaluasi = () => {
    MySwal.fire({
      icon: 'warning',
      title: 'Apakah anda yakin?',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonText: 'Ya',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-flat-primary',
        cancelButton: 'btn btn-light '
      }
    });
  };

  const ajukanSKK = () => {
    MySwal.fire({
      icon: 'warning',
      title: 'Apakah anda yakin?',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonText: 'Ya',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-flat-primary',
        cancelButton: 'btn btn-light '
      }
    });
  };

  const statusUsulanOptions = [
    { value: 'Proses Administrasi', label: 'Proses Administrasi' },
    { value: 'Menunggu Evaluasi', label: 'Menunggu Evaluasi' },
    { value: 'Menunggu SKK', label: 'Menunggu SKK' }
  ];

  const formEvaluasiOptions = [
    { value: 'PJS', label: 'PJS' },
    { value: 'Berjenjang', label: 'Berjenjang' }
  ];

  return (
    <div className="card">
      <div className="card-header bg-primary bg-opacity-10 text-primary fw-bold">
        Persyaratan
      </div>

      <div className="card-body">
        <div className="row mb-3">
          <label className="col-form-label col-2">Status Usulan</label>
          <div className="col-5">
            <Select
              className="basic-single bg-transparent"
              classNamePrefix="select"
              defaultValue={{ value: persyaratan.statusUsulan, label: persyaratan.statusUsulan }}
              options={statusUsulanOptions}
              placeholder="Pilih status"
            />
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-form-label col-2">No Surat Keluar</label>
          <div className="col-5">
            <input className="form-control me-2 col-3" value={persyaratan.noSuratKeluar} type="text" />
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-form-label col-2">Form Evaluasi</label>
          <div className="col-5">
            <Select
              className="basic-single bg-transparent"
              classNamePrefix="select"
              defaultValue={{ value: persyaratan.formEvaluasi, label: persyaratan.formEvaluasi }}
              options={formEvaluasiOptions}
              placeholder="Pilih form evaluasi"
            />
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-form-label col-2">Link Evaluasi</label>
          {persyaratan.linkEvaluasi === '' ? (
            <div className="col-5">
              <button type="button" className="btn btn-sm btn-primary" onClick={ajukanEvaluasi}>
                Ajukan Evaluasi
              </button>
            </div>
          )
            : (
              <div className="col-5 align-self-center">
                <a href="/evaluasi_berjenjang" target="_blank" rel="noreferrer">/evaluasi_berjenjang</a>
              </div>
            )}
        </div>
        <div className="row mb-3">
          <label className="col-form-label col-2">Hasil Evaluasi</label>
          <div className="col-5">
            <input className="form-control me-2 col-3" value={persyaratan.hasilEvaluasi} disabled />
          </div>
        </div>
        {persyaratan.suratKetetapanPegawai === '' ? (
          <div className="row mb-3">
            <label className="col-form-label col-2">Surat Ketetapan Kepegawaian</label>
            <div className="col-5">
              <button type="button" className="btn btn-sm btn-primary" onClick={ajukanSKK}>
                Ajukan SKK
              </button>
            </div>
          </div>
        )
          : (
            <div className="row mb-3 align-items-start">
              <label className="col-form-label col-2">Surat Ketetapan Kepegawaian</label>
              <div className="col-5 d-flex">
                <input className="form-control" value={persyaratan.suratKetetapanPegawai} disabled />
                <button type="button" className="btn btn-sm btn-danger ms-2" onClick={batalkan}>Batalkan</button>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

PersyaratanPromosiBertahapForm.defaultProps = {
  persyaratan: {
    noSuratKeluar: '',
    formEvaluasi: '',
    linkEvaluasi: '',
    hasilEvaluasi: ''
  }
};

PersyaratanPromosiBertahapForm.propTypes = {
  persyaratan: PropTypes.shape({
    statusUsulan: PropTypes.string.isRequired,
    noSuratKeluar: PropTypes.string,
    formEvaluasi: PropTypes.string,
    linkEvaluasi: PropTypes.string,
    hasilEvaluasi: PropTypes.string,
    suratKetetapanPegawai: PropTypes.string.isRequired
  })
};

export default PersyaratanPromosiBertahapForm;
