import PropTypes from 'prop-types';
import UsulanFilter from './UsulanFilter';
import UsulanTable from './UsulanTable';

const UsulanTab = ({ usulanRMP, tabType }) => (
  <div className="card-body">
    <UsulanFilter tabType={tabType} />
    <UsulanTable usulanRMP={usulanRMP} tabType={tabType} />
  </div>
);

UsulanTab.defaultProps = {
  tabType: ''
};

UsulanTab.propTypes = {
  usulanRMP: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      npk: PropTypes.string.isRequired,
      nama: PropTypes.string.isRequired,
      tipeUsulan: PropTypes.string.isRequired,
      tanggalDibuat: PropTypes.string.isRequired,
      tanggalUsulan: PropTypes.string.isRequired,
      noUsulan: PropTypes.string.isRequired,
      usulanDari: PropTypes.string.isRequired,
      mdg: PropTypes.string.isRequired,
      pbp: PropTypes.string.isRequired,
      posisiSekarang: PropTypes.shape({
        unitKerja: PropTypes.string.isRequired,
        jabatan: PropTypes.string.isRequired,
        jobGrade: PropTypes.string.isRequired,
        personalGrade: PropTypes.string.isRequired,
        atasan: PropTypes.string.isRequired,
        lokasi: PropTypes.string.isRequired
      }),
      posisiUsulan: PropTypes.shape({
        unitKerja: PropTypes.string.isRequired,
        jabatan: PropTypes.string.isRequired,
        jobGrade: PropTypes.string.isRequired,
        personalGrade: PropTypes.string.isRequired,
        atasan: PropTypes.string.isRequired,
        lokasi: PropTypes.string.isRequired
      })
    }).isRequired
  ).isRequired,
  tabType: PropTypes.string
};

export default UsulanTab;
