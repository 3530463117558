import { useNavigate } from 'react-router';

const JobDescTableRow = () => {
  const navigate = useNavigate();

  const navigateToDetail = () => {
    navigate('/jobdesc/detail');
  };

  return (
    <tr>
      <td className="cell-align-top">
        <p className="fs-sm mb-0">Sort ID/UKC</p>
        <p>111</p>
        <p className="fs-sm mb-0">Unit Kerja</p>
        <p>Departemen Administrasi Korporasi</p>
      </td>
      <td className="cell-align-top">
        <p className="fs-sm mb-0">JobDesc/Jabatan</p>
        <p>3/4</p>
      </td>
      <td className="cell-action cell-align-top text-center">
        <button type="button" className="btn btn-flat-primary btn-sm" onClick={navigateToDetail}>
          Data Jabatan
        </button>
      </td>
    </tr>
  );
};

export default JobDescTableRow;
