import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import ReactTooltip from 'react-tooltip';

const PromosiBertahapTableRow = ({ usulanRMP }) => {
  const navigate = useNavigate();

  const navigateToDetail = () => {
    navigate(`/rmp/usulan/detail/${usulanRMP.id}`);
  };

  return (
    <tr>
      <td className="cell-align-top">
        <span className="badge bg-warning me-2">{usulanRMP.tipeUsulan}</span>
        <p className="fs-sm mb-0">No Usulan:</p>
        <p>{usulanRMP.noUsulan}</p>
        <p className="fs-sm mb-0">NPK:</p>
        <p className="mb-0">{usulanRMP.npk}</p>
        <p>{usulanRMP.nama}</p>
      </td>
      <td className="cell-align-top w-120px">
        <p className="fs-sm mb-0">Unit Kerja</p>
        <p>{usulanRMP.posisiSekarang.unitKerja}</p>
        <p className="fs-sm mb-0">Jabatan</p>
        <p className="text-truncate w-120px" data-tip data-for={`tooltip-jabatan-sekarang-${usulanRMP.id}`}>
          {usulanRMP.posisiSekarang.jabatan}
        </p>
        <ReactTooltip id={`tooltip-jabatan-sekarang-${usulanRMP.id}`} className="wmax-160px text-center">
          {usulanRMP.posisiSekarang.jabatan}
        </ReactTooltip>
        <p className="fs-sm mb-0">JG | PG</p>
        <p>
          {usulanRMP.posisiSekarang.jobGrade}
          {' '}
          |
          {' '}
          {usulanRMP.posisiSekarang.personalGrade}
        </p>
        <p className="fs-sm mb-0">Lokasi</p>
        <p>{usulanRMP.posisiSekarang.lokasi}</p>
      </td>
      <td className="cell-align-top w-120px">
        <p className="fs-sm mb-0">Unit Kerja</p>
        <p>{usulanRMP.posisiUsulan.unitKerja}</p>
        <p className="fs-sm mb-0">Jabatan</p>
        <p className="text-truncate w-120px" data-tip data-for={`tooltip-jabatan-usulan-${usulanRMP.id}`}>{usulanRMP.posisiUsulan.jabatan}</p>
        <ReactTooltip id={`tooltip-jabatan-usulan-${usulanRMP.id}`} className="wmax-160px text-center">
          {usulanRMP.posisiUsulan.jabatan}
        </ReactTooltip>
        <p className="fs-sm mb-0">JG | PG</p>
        <p>
          {usulanRMP.posisiUsulan.jobGrade}
          {' '}
          |
          {' '}
          {usulanRMP.posisiUsulan.personalGrade}
        </p>
        <p className="fs-sm mb-0">Lokasi</p>
        <p>{usulanRMP.posisiUsulan.lokasi}</p>
      </td>
      <td className="cell-align-top">
        <p className="fs-sm mb-0">Status Usulan:</p>
        <p>{usulanRMP.statusUsulan}</p>
        <p className="fs-sm mb-0">Surat Keluar</p>
        <p>{usulanRMP.suratKeluar}</p>
        <p className="fs-sm mb-0">Surat Kembali</p>
        <p>{usulanRMP.suratKembali}</p>
        <p className="fs-sm mb-0">TMT Usulan</p>
        <p>{usulanRMP.tmtUsulan}</p>
        <p className="fs-sm mb-0">Status SKK</p>
        <p>{usulanRMP.statusSKK}</p>
        <button
          type="button"
          className="btn btn-flat-secondary btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#modal-catatan"
        >
          Catatan
        </button>
      </td>
      <td className="cell-action cell-align-top">
        <button type="button" className="btn btn-flat-primary btn-sm" onClick={navigateToDetail}>
          Update
        </button>
      </td>
    </tr>
  );
};

PromosiBertahapTableRow.defaultProps = {
  usulanRMP: {
    statusUsulan: '',
    suratKeluar: '',
    suratKembali: '',
    tmtUsulan: '',
    catatan: '',
    statusSKK: ''
  }
};

PromosiBertahapTableRow.propTypes = {
  usulanRMP: PropTypes.shape({
    id: PropTypes.number.isRequired,
    npk: PropTypes.string.isRequired,
    nama: PropTypes.string.isRequired,
    tipeUsulan: PropTypes.string.isRequired,
    tanggalDibuat: PropTypes.string.isRequired,
    tanggalUsulan: PropTypes.string.isRequired,
    noUsulan: PropTypes.string.isRequired,
    usulanDari: PropTypes.string.isRequired,
    mdg: PropTypes.string.isRequired,
    pbp: PropTypes.string.isRequired,
    statusUsulan: PropTypes.string,
    suratKeluar: PropTypes.string,
    suratKembali: PropTypes.string,
    tmtUsulan: PropTypes.string,
    catatan: PropTypes.string,
    statusSKK: PropTypes.string,
    posisiSekarang: PropTypes.shape({
      unitKerja: PropTypes.string.isRequired,
      jabatan: PropTypes.string.isRequired,
      jobGrade: PropTypes.string.isRequired,
      personalGrade: PropTypes.string.isRequired,
      atasan: PropTypes.string.isRequired,
      lokasi: PropTypes.string.isRequired
    }),
    posisiUsulan: PropTypes.shape({
      unitKerja: PropTypes.string.isRequired,
      jabatan: PropTypes.string.isRequired,
      jobGrade: PropTypes.string.isRequired,
      personalGrade: PropTypes.string.isRequired,
      atasan: PropTypes.string.isRequired,
      lokasi: PropTypes.string.isRequired
    })
  })
};

export default PromosiBertahapTableRow;
