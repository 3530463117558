/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
const KaryawanCard = () => (
  <div className="card">
    <div className="card-header bg-primary bg-opacity-10 text-primary fw-bold">
      Data Karyawan
    </div>
    <div className="card-body">
      <div className="row mb-3">
        <label className="col-form-label col-2">NPK</label>
        <div className="col-4">
          <div className="input-group">
            <input type="text" className="form-control" value="319792" disabled />
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <label className="col-form-label col-2">Nama</label>
        <div className="col-4">
          <div className="input-group">
            <input type="text" className="form-control" value="John Doe" disabled />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default KaryawanCard;
