import SimpleList from '../../Components/SimpleList/SimpleList';
import ideImprovement from '../../dummy_data/ideImprovement';
import IdeCard from './IdeCard';
import KaryawanCard from './KaryawanCard';
import PosisiCard from './PosisiCard';

const IdeImprovementPage = () => (
  <div className="page-content">
    <div className="content-wrapper">
      <div className="content-inner">
        <div className="page-header">
          <div className="page-header-content container d-lg-flex">
            <div className="d-flex">
              <h4 className="page-title mb-0">
                Ide Improvement
              </h4>
            </div>
          </div>
        </div>
        <div className="content container pt-0">
          <div className="d-flex flex-row-reverse">
            <button type="button" className="btn btn-flat-primary mb-2">
              to PDF
            </button>
          </div>
          <KaryawanCard />
          <PosisiCard />
          <SimpleList
            data={ideImprovement}
            renderItem={(item) => (
              <IdeCard
                key={item.id}
                ide={item}
              />
            )}
          />
          <div className="d-flex flex-row-reverse">
            <button type="submit" className="btn btn-primary">
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default IdeImprovementPage;
