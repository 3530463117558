import {
  House
} from 'phosphor-react';
import { Link } from 'react-router-dom';

const Error404Page = () => (
  <div className="page-content">
    <div className="content-wrapper">
      <div className="content-inner">
        <div className="content d-flex justify-content-center align-items-center">

          <div className="flex-fill">

            <div className="text-center mb-4">
              <img src="../../../assets/images/error_bg.svg" className="img-fluid mb-3" height="230" alt="" />
              <h1 className="display-3 fw-semibold lh-1 mb-3">404</h1>
              <h6 className="w-md-25 mx-md-auto">
                Oops, an error has occurred.
                {' '}
                <br />
                {' '}
                The resource requested could not be found on this server.
              </h6>
            </div>

            <div className="text-center">
              <Link to="/" className="btn btn-primary">
                <House size={22} className="me-2" />
                Return to dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Error404Page;
