/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

import { useState } from 'react';
import Select from 'react-select';

const SKKPrintFilter = () => {
  const [selectedStatusUHC, setSelectedStatusUHC] = useState(null);
  const [selectedUnitKerjaSekarang, setSelectedUnitKerjaSekarang] = useState(null);
  const [selectLokasiSekarang, setSelectedLokasiSekarang] = useState(null);
  const [selectedUnitKerjaUsulan, setSelectedUnitKerjaUsulan] = useState(null);
  const [selectedLokasiUsulan, setSelectedLokasiUsulan] = useState(null);

  const statusUHCnOptions = [
    { value: 'Jobdesc', label: 'Jobdesc' },
    { value: 'Penjadwalan', label: 'Penjadwalan' },
    { value: 'Pelaksanaan', label: 'Pelaksanaan' },
    { value: 'Hasil', label: 'Hasil' }
  ];

  const unitKerjaOptions = [
    { value: '1', label: 'Departemen Perencanaan, Penerimaan & Pergudangan' },
    { value: '2', label: 'Kompartemen Pemeliharaan' },
    { value: '3', label: 'Departemen Akuntansi' },
    { value: '4', label: 'Departemen Anggaran' },
    { value: '5', label: 'Departemen Pengembangan Korporat' },
    { value: '6', label: 'Direktorat Keuangan & Umum' },
    { value: '7', label: 'Departemen Operasi Pabrik 4' },
    { value: '8', label: 'Direktorat Operasi & Produksi' },
    { value: '9', label: 'Departemen Inovasi & Pengembangan Manajemen' },
    { value: '10', label: 'Departemen Keamanan' },
    { value: '11', label: 'Departemen Administrasi Korporasi' }
  ];

  const lokasiOptions = [
    { value: '1', label: 'Bontang' },
    { value: '2', label: 'Makassar' },
    { value: '3', label: 'Surabaya' },
    { value: '4', label: 'Jakarta' },
    { value: '5', label: 'Bintuni' }
  ];

  const statusPrintOptions = [
    { value: '2', label: 'D1' },
    { value: '3', label: 'D2' },
    { value: '4', label: 'D3' },
    { value: '5', label: 'SDM' }
  ];

  return (
    <div className="card">
      <div className="card-header bg-primary bg-opacity-10 text-primary fw-bold">
        <div className="mb-0">Filter SKK</div>
      </div>

      <div className="card-body">
        <div className="row">
          <div className="col-lg-4 mb-3">
            <label className="form-label">No Usulan</label>
            <input type="text" className="form-control" />
          </div>
          <div className="col-lg-4 mb-3">
            <label className="form-label">NPK</label>
            <input type="text" className="form-control" />
          </div>
          <div className="col-lg-4 mb-3">
            <label className="form-label">Nama Karyawan</label>
            <input type="text" className="form-control" />
          </div>
          <div className="col-lg-3 mb-3">
            <label className="form-label">Unit Kerja Sekarang</label>
            <Select
              className="basic-single bg-transparent"
              classNamePrefix="select"
              options={unitKerjaOptions}
              defaultValue={null}
              placeholder="Pilih unit kerja"
              onChange={setSelectedUnitKerjaSekarang}
            />
          </div>
          <div className="col-lg-3 mb-3">
            <label className="form-label">Lokasi Sekarang</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              options={lokasiOptions}
              defaultValue={null}
              placeholder="Pilih lokasi"
              onChange={setSelectedLokasiSekarang}
            />
          </div>
          <div className="col-lg-3 mb-3">
            <label className="form-label">Unit Kerja Usulan</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              options={unitKerjaOptions}
              defaultValue={null}
              placeholder="Pilih unit kerja"
              onChange={setSelectedUnitKerjaUsulan}
            />
          </div>
          <div className="col-lg-3 mb-3">
            <label className="form-label">Lokasi Usulan</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              options={lokasiOptions}
              defaultValue={null}
              placeholder="Pilih lokasi"
              onChange={setSelectedLokasiUsulan}
            />
          </div>
          <div className="col-lg-3 mb-3">
            <label className="form-label">Status Print</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              options={statusPrintOptions}
              defaultValue={null}
              placeholder="Pilih status"
            />
          </div>
        </div>
        <div className="d-flex flex-row-reverse">
          <button type="button" className="btn btn-primary my-1 me-2">Filter</button>
          <button type="button" className="btn btn-light my-1 me-2">Reset</button>
        </div>
      </div>
    </div>
  );
};

export default SKKPrintFilter;
