/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/control-has-associated-label */

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const DetailPelaksanaanModal = () => {
  const MySwal = withReactContent(Swal);

  const buatUndangan = () => {
    MySwal.fire({
      icon: 'warning',
      title: 'Apakah anda yakin?',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonText: 'Ya',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-flat-primary',
        cancelButton: 'btn btn-light '
      }
    });
  };

  return (
    <div id="modal-detail-pelaksanaan" className="modal fade" tabIndex="-1">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Detail Pelaksanaan</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" />
          </div>

          <div className="modal-body">
            <div className="row border-bottom">
              <div className="col-lg-4 mb-3">
                <label className="form-label">Hari/Tanggal/Waktu</label>
                <input type="text" className="form-control" disabled />
              </div>
              <div className="col-lg-4 mb-3">
                <label className="form-label">Tempat</label>
                <input type="text" className="form-control" disabled />
              </div>
              <div className="col-lg-4 mb-3">
                <label className="form-label">Asessor</label>
                <input type="text" className="form-control" disabled />
              </div>
            </div>
            <div className="row border-bottom">
              <div className="col-lg-8 my-3">
                <label className="form-label">Witness</label>
                <div className="input-group">
                  <input type="text" className="form-control" disabled />
                  <button
                    className="btn btn-primary"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-pilih-witness"
                  >
                    Pilih Witness
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4 mt-3">
                <button type="button" className="btn btn-flat-secondary" onClick={buatUndangan}>Buat Undangan </button>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-link" data-bs-dismiss="modal">Tutup</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailPelaksanaanModal;
