/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

import { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { tipeUsulanRMP } from '../../../../utils/enum';

const PosisiForm = ({ posisiSekarang }) => {
  const { ROTASI, MUTASI, PROMOSI } = tipeUsulanRMP;
  const [selectedUnitKerjaUsulan, setSelectedUnitKerjaUsulan] = useState(null);
  const [selectedLokasiUsulan, setSelectedLokasiUsulan] = useState(null);

  const unitKerjaOptions = [
    { value: 'Departemen Perencanaan, Penerimaan & Pergudangan', label: 'Departemen Perencanaan, Penerimaan & Pergudangan' },
    { value: 'Kompartemen Pemeliharaan', label: 'Kompartemen Pemeliharaan' },
    { value: 'Departemen Akuntansi', label: 'Departemen Akuntansi' },
    { value: 'Departemen Anggaran', label: 'Departemen Anggaran' },
    { value: 'Departemen Pengembangan Korporat', label: 'Departemen Pengembangan Korporat' },
    { value: 'Direktorat Keuangan & Umum', label: 'Direktorat Keuangan & Umum' },
    { value: 'Departemen Operasi Pabrik 4', label: 'Departemen Operasi Pabrik 4' },
    { value: 'Direktorat Operasi & Produksi', label: 'Direktorat Operasi & Produksi' },
    { value: 'Departemen Inovasi & Pengembangan Manajemen', label: 'Departemen Inovasi & Pengembangan Manajemen' },
    { value: 'Departemen Keamanan', label: 'Departemen Keamanan' },
    { value: 'Departemen Administrasi Korporasi', label: 'Departemen Administrasi Korporasi' }
  ];

  const lokasiOptions = [
    { value: 'Bontang', label: 'Bontang' },
    { value: 'Makassar', label: 'Makassar' },
    { value: 'Surabaya', label: 'Surabaya' },
    { value: 'Jakarta', label: 'Jakarta' },
    { value: 'Bintuni', label: 'Bintuni' }
  ];

  return (
    <>
      <div className="card">
        <div className="card-header bg-primary bg-opacity-10 text-primary fw-bold">
          Pengajuan Posisi
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-6 pe-4" style={{ borderRight: '1px solid #D1D5DB' }}>
              <p className="fs-lg fw-semibold mb-2">Posisi Sekarang</p>
              <div className="mb-3">
                <label className="form-label">Unit Kerja</label>
                <input type="text" className="form-control" defaultValue={posisiSekarang.unitKerja} disabled />
              </div>
              <div className="mb-3">
                <label className="form-label">Jabatan</label>
                <input type="text" className="form-control" defaultValue={posisiSekarang.jabatan} disabled />
              </div>
              <div className="row mb-3">
                <div className="col-4">
                  <label className="form-label">Job Grade</label>
                  <input type="text" className="form-control" defaultValue={posisiSekarang.jobGrade} disabled />
                </div>
                <div className="col-4">
                  <label className="form-label">Personal Grade</label>
                  <input type="text" className="form-control" defaultValue={posisiSekarang.personalGrade} disabled />
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Atasan</label>
                <input type="text" className="form-control" defaultValue={posisiSekarang.atasan} disabled />
              </div>
              <div className="mb-3">
                <label className="form-label">Lokasi</label>
                <input type="text" className="form-control" defaultValue={posisiSekarang.lokasi} disabled />
              </div>
            </div>
            <div className="col-lg-6 ps-4">
              <p className="fs-lg fw-semibold mb-2">Posisi Usulan</p>
              <div className="mb-3">
                <label className="form-label">Unit Kerja</label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  options={unitKerjaOptions}
                  placeholder="Pilih unit kerja"
                  onChange={setSelectedUnitKerjaUsulan}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Jabatan</label>
                <input type="text" className="form-control" />
              </div>
              <div className="row mb-3">
                <div className="col-4">
                  <label className="form-label">Job Grade</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="col-4">
                  <label className="form-label">Personal Grade</label>
                  <input type="text" className="form-control" />
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Atasan</label>
                <input type="text" className="form-control" disabled />
              </div>
              <div className="mb-3">
                <label className="form-label">Lokasi</label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  options={lokasiOptions}
                  placeholder="Pilih lokasi"
                  onChange={setSelectedLokasiUsulan}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header bg-primary bg-opacity-10 text-primary fw-bold">
          Catatan
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-12 mb-3">
              <textarea className="form-control" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

PosisiForm.propTypes = {
  posisiSekarang: PropTypes.shape({
    unitKerja: PropTypes.string.isRequired,
    jabatan: PropTypes.string.isRequired,
    jobGrade: PropTypes.string.isRequired,
    personalGrade: PropTypes.string.isRequired,
    atasan: PropTypes.string.isRequired,
    lokasi: PropTypes.string.isRequired
  }).isRequired
};

export default PosisiForm;
