/* eslint-disable jsx-a11y/label-has-associated-control */

import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { tipeUsulanRMP } from '../../../../utils/enum';
import { formatDateToInternationalFormat } from '../../../../utils/utils';
import KaryawanDetail from '../UsulanBaruPage/KaryawanDetail';
import PersyaratanPromosiBertahapForm from './PersyaratanPromosiBertahapForm';
import PersyaratanPromosiForm from './PersyaratanPromosiForm';
import PersyaratanRotasiMutasiForm from './PersyaratanRotasiMutasiForm';

/* eslint-disable jsx-a11y/label-has-for */
const UsulanForm = ({ usulan }) => {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const {
    ROTASI, MUTASI, PROMOSI, PROMOSI_BERTAHAP
  } = tipeUsulanRMP;
  const karyawan = (({
    nama, mdg, pbp, posisiSekarang, posisiUsulan
  }) => ({
    nama, mdg, pbp, posisiSekarang, posisiUsulan
  }))(usulan);
  const persyaratan = (({
    statusUsulan, persetujuanBoDPKT, persetujuanPI, suratKetetapanPegawai,
    asesmenPotensi, asesmenPotensiValue, ideImprovement,
    statusUHC, tanggalPermintaanUHC, tanggalSelesaiUHC,
    hasilUHC, hasilUHCValue, statusUsulanDanHasil
  }) => ({
    statusUsulan,
    persetujuanBoDPKT,
    persetujuanPI,
    suratKetetapanPegawai,
    asesmenPotensi,
    asesmenPotensiValue,
    ideImprovement,
    statusUHC,
    tanggalPermintaanUHC,
    tanggalSelesaiUHC,
    hasilUHC,
    hasilUHCValue,
    statusUsulanDanHasil
  }))(usulan);
  const persyaratanPromosiBertahap = (({
    statusUsulan, noSuratKeluar, formEvaluasi, linkEvaluasi,
    hasilEvaluasi, suratKetetapanPegawai
  }) => ({
    statusUsulan,
    noSuratKeluar,
    formEvaluasi,
    linkEvaluasi,
    hasilEvaluasi,
    suratKetetapanPegawai
  }))(usulan);

  const hapusUsulan = () => (
    MySwal.fire({
      icon: 'warning',
      title: 'Apakah anda yakin akan menghapus usulan RMP karyawan?',
      input: 'text',
      inputPlaceholder: 'Masukkan NPK karyawan yang akan dihapus',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonText: 'Hapus Usulan',
      confirmButtonColor: '#F1E2E8'
    })
  );

  const navigateToPreviousPage = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="d-flex flex-row-reverse mb-3">
        <button type="button" className="btn btn-flat-danger" onClick={hapusUsulan}>
          Hapus Usulan
        </button>
      </div>
      <div className="card">
        <div className="card-header bg-primary bg-opacity-10 text-primary fw-bold">
          Usulan
          {' '}
          {usulan.tipeUsulan}
          {' '}
          {usulan.nama}
        </div>

        <div className="card-body">
          <div className="row mb-3">
            <label className="col-form-label col-2">No Surat</label>
            <div className="col-4">
              <div className="input-group">
                <input className="form-control" value={usulan.noUsulan} disabled />
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-form-label col-2">Diusulkan Oleh</label>
            <div className="col-4">
              <div className="input-group">
                <input type="text" className="form-control" value={usulan.usulanDari} disabled />
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-form-label col-2">Tanggal Usulan</label>
            <div className="col-4">
              <div className="input-group">
                <input type="text" className="form-control" value={formatDateToInternationalFormat(usulan.tanggalUsulan)} disabled />
              </div>
            </div>
          </div>
        </div>
      </div>

      <KaryawanDetail karyawan={karyawan} />
      {[ROTASI, MUTASI].includes(usulan.tipeUsulan)
      && <PersyaratanRotasiMutasiForm persyaratan={persyaratan} />}
      {usulan.tipeUsulan === PROMOSI
      && <PersyaratanPromosiForm persyaratan={persyaratan} tipePromosi={usulan.tipePromosi} />}
      {usulan.tipeUsulan === PROMOSI_BERTAHAP
      && <PersyaratanPromosiBertahapForm persyaratan={persyaratanPromosiBertahap} />}

      <div className="d-flex">
        <button type="submit" className="btn btn-danger">
          Selesai
        </button>
        <button type="button" className="btn btn-light ms-auto me-2" onClick={navigateToPreviousPage}>
          Kembali
        </button>
        <button type="submit" className="btn btn-primary">
          Simpan
        </button>
      </div>
    </>
  );
};

UsulanForm.defaultProps = {
  usulan: {
    tipePromosi: '',
    asesmenPotensi: '',
    asesmenPotensiValue: '',
    ideImprovement: '',
    statusUHC: '',
    tanggalPermintaanUHC: '',
    tanggalSelesaiUHC: '',
    hasilUHC: '',
    hasilUHCValue: '',
    statusUsulanDanHasil: '',
    persetujuanBoDPKT: '',
    persetujuanPI: '',
    noSuratKeluar: '',
    formEvaluasi: '',
    linkEvaluasi: '',
    hasilEvaluasi: ''
  }
};

UsulanForm.propTypes = {
  usulan: PropTypes.shape({
    id: PropTypes.number.isRequired,
    npk: PropTypes.string.isRequired,
    nama: PropTypes.string.isRequired,
    tipeUsulan: PropTypes.string.isRequired,
    tanggalDibuat: PropTypes.string.isRequired,
    tanggalUsulan: PropTypes.string.isRequired,
    noUsulan: PropTypes.string.isRequired,
    usulanDari: PropTypes.string.isRequired,
    mdg: PropTypes.string.isRequired,
    pbp: PropTypes.string.isRequired,
    statusUsulan: PropTypes.string.isRequired,
    tipePromosi: PropTypes.string,
    asesmenPotensi: PropTypes.string,
    asesmenPotensiValue: PropTypes.string,
    ideImprovement: PropTypes.string,
    statusUHC: PropTypes.string,
    tanggalPermintaanUHC: PropTypes.string,
    tanggalSelesaiUHC: PropTypes.string,
    hasilUHC: PropTypes.string,
    hasilUHCValue: PropTypes.string,
    statusUsulanDanHasil: PropTypes.string,
    persetujuanBoDPKT: PropTypes.string,
    persetujuanPI: PropTypes.string,
    noSuratKeluar: PropTypes.string,
    formEvaluasi: PropTypes.string,
    linkEvaluasi: PropTypes.string,
    hasilEvaluasi: PropTypes.string,
    suratKetetapanPegawai: PropTypes.string.isRequired,
    posisiSekarang: PropTypes.shape({
      unitKerja: PropTypes.string.isRequired,
      jabatan: PropTypes.string.isRequired,
      jobGrade: PropTypes.string.isRequired,
      personalGrade: PropTypes.string.isRequired,
      atasan: PropTypes.string.isRequired,
      lokasi: PropTypes.string.isRequired
    }),
    posisiUsulan: PropTypes.shape({
      unitKerja: PropTypes.string.isRequired,
      jabatan: PropTypes.string.isRequired,
      jobGrade: PropTypes.string.isRequired,
      personalGrade: PropTypes.string.isRequired,
      atasan: PropTypes.string.isRequired,
      lokasi: PropTypes.string.isRequired
    })
  })
};

export default UsulanForm;
