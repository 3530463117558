import JobDescDetailFilter from './JobDescDetailFilter';
import JobDescDetailTable from './JobDescDetailTable';
import UpdateJabatanModal from './UpdateJabatanModal';

const JobDescDetailPage = () => (
  <div className="page-content">
    <div className="content-wrapper">
      <div className="content-inner">
        <div className="page-header">
          <div className="page-header-content container d-lg-flex">
            <div className="d-flex">
              <h4 className="page-title mb-0">
                Home -
                {' '}
                <span className="fw-normal">Dashboard</span>
              </h4>
            </div>
          </div>
        </div>
        <div className="content container pt-0">
          <JobDescDetailFilter />
          <JobDescDetailTable />
        </div>
      </div>
    </div>
    <UpdateJabatanModal />
  </div>
);

export default JobDescDetailPage;
