export const tipeUsulanRMP = {
  ROTASI: 'Rotasi',
  MUTASI: 'Mutasi',
  PROMOSI: 'Promosi',
  PROMOSI_BERTAHAP: 'Promosi Bertahap'
};

export const statusUHC = {
  JOBDESC: 'Jobdesc',
  PENJADWALAN: 'Penjadwalan',
  PELAKSANAAN: 'Pelaksanaan',
  HASIL: 'Hasil'
};

export const skkStatus = {
  ADMINISTRASI: 'Administrasi',
  PRINT: 'Print',
  PENANDATANGANAN: 'Penandatanganan'
};

export default {
  tipeUsulanRMP,
  statusUHC,
  skkStatus
};
