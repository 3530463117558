import PropTypes from 'prop-types';
import SimpleList from '../../../../Components/SimpleList/SimpleList';
import TablePagination from '../../../../Components/TablePagination/TablePagination';
import { tipeUsulanRMP } from '../../../../utils/enum';
import PromosiBertahapTableRow from './PromosiBertahapTableRow';
import PromosiTableRow from './PromosiTableRow';
import RotasiMutasiTableRow from './RotasiMutasiTableRow';
import UHCTableRow from './UHCTableRow';

const UsulanTable = ({ usulanRMP, tabType }) => {
  const {
    ROTASI, MUTASI, PROMOSI, PROMOSI_BERTAHAP
  } = tipeUsulanRMP;

  const renderRow = (item) => {
    if (tabType === 'uhc') {
      return (
        <UHCTableRow
          key={item.id}
          usulanRMP={item}
        />
      );
    }
    if ([ROTASI, MUTASI].includes(item.tipeUsulan)) {
      return (
        <RotasiMutasiTableRow
          key={item.id}
          usulanRMP={item}
        />
      );
    }
    if (item.tipeUsulan === PROMOSI) {
      return (
        <PromosiTableRow
          key={item.id}
          usulanRMP={item}
        />
      );
    }
    if (item.tipeUsulan === PROMOSI_BERTAHAP) {
      return (
        <PromosiBertahapTableRow
          key={item.id}
          usulanRMP={item}
        />
      );
    }
    return '';
  };

  return (
    <>
      <div className="card">
        <div className="card-body p-0">
          <div className="table-responsive border rounded">
            <table className="table">
              <thead className="bg-primary bg-opacity-10 text-primary">
                <tr>
                  <th>Usulan Karyawan</th>
                  <th>Posisi Sekarang</th>
                  <th>Posisi Usulan</th>
                  <th>Progress</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                <SimpleList
                  data={usulanRMP}
                  renderItem={(item) => renderRow(item)}
                />
              </tbody>
            </table>
          </div>

        </div>
      </div>
      <TablePagination />
    </>
  );
};

UsulanTable.defaultProps = {
  tabType: ''
};

UsulanTable.propTypes = {
  usulanRMP: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      npk: PropTypes.string.isRequired,
      nama: PropTypes.string.isRequired,
      tipeUsulan: PropTypes.string.isRequired,
      tanggalDibuat: PropTypes.string.isRequired,
      tanggalUsulan: PropTypes.string.isRequired,
      noUsulan: PropTypes.string.isRequired,
      usulanDari: PropTypes.string.isRequired,
      mdg: PropTypes.string.isRequired,
      pbp: PropTypes.string.isRequired,
      posisiSekarang: PropTypes.shape({
        unitKerja: PropTypes.string.isRequired,
        jabatan: PropTypes.string.isRequired,
        jobGrade: PropTypes.string.isRequired,
        personalGrade: PropTypes.string.isRequired,
        atasan: PropTypes.string.isRequired,
        lokasi: PropTypes.string.isRequired
      }),
      posisiUsulan: PropTypes.shape({
        unitKerja: PropTypes.string.isRequired,
        jabatan: PropTypes.string.isRequired,
        jobGrade: PropTypes.string.isRequired,
        personalGrade: PropTypes.string.isRequired,
        atasan: PropTypes.string.isRequired,
        lokasi: PropTypes.string.isRequired
      })
    }).isRequired
  ).isRequired,
  tabType: PropTypes.string
};

export default UsulanTable;
