/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/control-has-associated-label */

import { useState } from 'react';
import Select from 'react-select';

const PilihWitnessModal = () => {
  const [witness, setWitness] = useState('');

  const witnessOption = [
    { value: 'Ratna', label: 'Ratna' },
    { value: 'Endah', label: 'Endah' },
    { value: 'Winda', label: 'Winda' },
    { value: 'Anton', label: 'Anton' },
    { value: 'Difa', label: 'Difa' }
  ];

  return (
    <div id="modal-pilih-witness" className="modal fade" tabIndex="-1">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Catatan RMP</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" />
          </div>

          <div className="modal-body">
            <div className="row">
              <div className="col-lg-12 mb-3">
                <label className="form-label">Witness</label>
                <Select
                  className="basic-single bg-transparent"
                  classNamePrefix="select"
                  options={witnessOption}
                  defaultValue={null}
                  placeholder="Pilih witness"
                  onChange={setWitness}
                />
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-link" data-bs-dismiss="modal">Batal</button>
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Simpan</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PilihWitnessModal;
