/* eslint-disable jsx-a11y/control-has-associated-label */

const UploadEvaluasiModal = () => (
  <div id="modal-upload-evaluasi" className="modal fade" tabIndex="-1">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Upload Evaluasi</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" />
        </div>

        <div className="modal-body">
          <input type="file" />
        </div>

        <div className="modal-footer">
          <button type="button" className="btn btn-link" data-bs-dismiss="modal">Batal</button>
          <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Simpan</button>
        </div>
      </div>
    </div>
  </div>
);

export default UploadEvaluasiModal;
