/* eslint-disable jsx-a11y/control-has-associated-label */

const LinkPengisianModal = () => (
  <div id="modal-link-pengisian" className="modal fade" tabIndex="-1">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Catatan RMP</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" />
        </div>
        <div className="modal-body">
          <a href="https://psdm.sdmpkt.com/uhc/penjadwalan/016jkxmzyirnplg7owfb" target="_blank" rel="noreferrer">
            https://psdm.sdmpkt.com/uhc/penjadwalan/016jkxmzyirnplg7owfb
          </a>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-link" data-bs-dismiss="modal">Tutup</button>
        </div>
      </div>
    </div>
  </div>
);

export default LinkPengisianModal;
